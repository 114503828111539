import React from "react"
import PropTypes from "prop-types"
import Textarea from 'react-textarea-autosize';
import WordsPerSentenceFromGroup from './WordsPerSentenceFromGroup';
import TranslationsFormGroup from './TranslationsFormGroup';

class TatoebaImporter extends React.Component {
  constructor(props) {
    super(props);

    this.csrfToken = $('meta[name="csrf-token"]').attr('content');

    this.state = {
      importMode: 'search',
      search: 'sentences',
      query: '',
      url: '',
      sentences: [],
      results: [],
      name: '',
      articleVisible: false
    };
  }

  renderImportModeControl() {
    return (
      <div className="form-group">
        <div className="form-check form-check-inline">
          <input className="form-check-input" id="import-mode-search" type="radio" name="import-mode" value="search" checked={this.state.importMode === 'search'} onChange={(e) => this.setState({ importMode: e.target.value })} />
          <label className="form-check-label" htmlFor="import-mode-search">Search Tatoeba sentences</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" id="import-mode-url" type="radio" name="import-mode" value="url" checked={this.state.importMode === 'url'} onChange={(e) => this.setState({ importMode: e.target.value })} />
          <label className="form-check-label" htmlFor="import-mode-url">Add Tatoeba sentence via URL</label>
        </div>
      </div>
    );
  }

  renderImportControl() {
    if(this.state.importMode === 'search') {
      return this.renderSearchControl();
    }
    else {
      return this.renderLinkControl();
    }
  }

  renderLinkControl() {
  }

  onSearchSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    $.ajax({
      url: this.props.queryPath,
      method: 'post',
      data: {
        query: this.state.query,
        search: this.state.search
      }
    })
      .done((results) => {
        this.setState({
          loading: false,
          results: results
        });
      })
      .fail(() => {
        alert('Oh no! There was an error completing the query. Please try again and let us know if you see this error again.');
        this.setState({ loading: false });
        console.log(arguments);
      });
  }

  renderSearchControl() {
    return (
      <div className="card card-default">
        <div className="card-body">
          <form onSubmit={this.onSearchSubmit.bind(this)}>
            <div className="form-group">
              <input className="form-control" name="query" autoFocus={true} value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
            </div>
            <div className="form-group mb-0">
              <div className="form-check form-check-inline">
                <input className="form-check-input" id="search-sentences" type="radio" name="search-control" value="sentences" checked={this.state.search === 'sentences'} onChange={(e) => this.setState({ search: e.target.value })}/>
                <label className="form-check-label" htmlFor="search-sentences">Sentences</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" id="search-translations" type="radio" name="search-control" value="translations" checked={this.state.search === 'translations'} onChange={(e) => this.setState({ search: e.target.value })}/>
                <label className="form-check-label" htmlFor="search-translations">Translations</label>
              </div>
              <button className="btn btn-primary" disabled={this.state.loading}>Search</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderArticle() {
    return (
      <div className={'card card-default mb-2 d-' + (this.state.articleVisible ? 'block' : 'none')}>
        <div className="card-body">
          <form action={this.props.importPath} method="post" ref={(el) => this.form = el}>
            <input type="hidden" name="authenticity_token" value={this.csrfToken} />
            <div className="form-group">
              <label>Name</label>
              <input name="article[name]" className="form-control" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
            </div>
            <WordsPerSentenceFromGroup min={this.props.wordsPerSentenceMin} max={this.props.wordsPerSentenceMax} />
            <TranslationsFormGroup addTranslations={this.props.addTranslations} />
            <ol>
              {this.state.sentences.map((s, i) => (
                <li className="article-sentence">{s} <small><a href="javascript:void(0);" onClick={() => this.removeSentence(s)}>remove</a></small></li>
              ))}
            </ol>
            <input type="hidden" name="article[text]" value={this.state.sentences.join("\n")} />
          </form>
        </div>
      </div>
    );
  }

  removeSentence(sentence) {
    this.setState({ sentences: this.state.sentences.slice(0).filter((s) => s !== sentence) });
  }

  addSentence(sentence) {
    this.setState({ sentences: this.state.sentences.slice(0).concat([sentence]) });
  }

  renderSearchResult(sentence, k) {
    const included = this.state.sentences.includes(sentence);
    return (
      <li key={k} className="list-group-item search-result-sentence">
        <button
          onClick={() => included ? this.removeSentence(sentence) : this.addSentence(sentence)}
          className={'btn btn-sm mr-2 btn-' + (included ? 'success' : 'secondary')}
        >
          <i className={'fa fa-' + (included ? 'check' : 'plus')}></i>
        </button>
        {sentence}
      </li>
    );
  }

  renderSearchResults() {
    if(!this.state.results.length) {
      return null;
    }

    if(this.state.loading) {
      return <div className="mb-2 text-center">Loading...</div>;
    }

    return (
      <div className="card mt-2">
        <ul className="list-group list-group-flush">
          {this.state.results.map((r, i) => this.renderSearchResult(r[0], i))}
        </ul>
      </div>
    );
  }

  import() {
    if(!this.state.name) {
      this.setState({ articleVisible: true });
      return alert('Please enter a name then try again!');
    }

    if(!this.state.sentences.length) {
      return alert('Please add smme sentences then try again!');
    }

    this.form.submit();
  }

  render() {
    return (
      <div className="mb-5">
        <p>Search the Tatoeba database of sentences. Note: results here may not be up-to-date with what's currently on Tatoeba.{/* You can also import invidual sentences via their URL on Tatoeba.*/}</p>
        <p className="text-right">
          <button className="btn btn-primary mr-2" onClick={() => this.setState({ articleVisible: !this.state.articleVisible })}><i className={'mr-1 fa fa-chevron-circle-' + (this.state.articleVisible ? 'up' : 'down')}></i> {this.state.sentences.length} Sentences</button>
          <button className="btn btn-primary" onClick={() => this.import()}>Import</button>
        </p>
        {this.renderArticle()}
        {/*this.renderImportModeControl()*/}
        {this.renderImportControl()}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default TatoebaImporter;
