import React from "react"
import store from '../store';
import defaultGameSettings from '../defaultGameSettings';

export default class GameSettings extends React.Component {
  static defaultProps = {
    gameSettings: defaultGameSettings
  }

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      voices: store.getState().voices,

      ttsVoice: props.gameSettings.ttsVoice,
      autoPlayTts: props.gameSettings.autoPlayTts,
      soundEffects: props.gameSettings.soundEffects,
      darkMode: props.gameSettings.darkMode
    };
  }

  componentDidMount() {
    store.subscribe(() => this.setState({ voices: store.getState().voices }));
    this.updateDarkMode();
  }

  componentDidUpdate() {
    this.updateDarkMode();
  }

  updateDarkMode() {
    $('body').toggleClass('bg-dark text-white', this.state.darkMode);
  }

  saveAndUpdate() {
    const { updateGameSettingsPath, onUpdate } = this.props;
    const s = this.state;
    const gameSettings = {
      ttsVoice: s.ttsVoice,
      autoPlayTts: s.autoPlayTts,
      soundEffects: s.soundEffects,
      darkMode: s.darkMode
    };
    onUpdate(gameSettings);

    if(!updateGameSettingsPath) {
      return false;
    }

    // send to server and trigger update
    $.ajax({
      headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
      url: updateGameSettingsPath,
      method: 'post',
      data: { game_settings: JSON.stringify(gameSettings) }
    });
  }

  onTtsVoicesChange(event) {
    this.setState({ ttsVoice: event.target.value }, () => this.saveAndUpdate());
  }

  onCheckboxChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => this.saveAndUpdate());
  }

  renderTtsVoices() {
    if(!this.state.voices) {
      return <em>No voices found!</em>;
    }
    const regex = new RegExp('^' + this.props.twoLetterLanguageCode);
    const options = this.state.voices.filter((v) => v.lang.match(regex)).map((v) => <option key={v.name} value={v.name}>{v.lang} {v.name}</option>);
    return (
      <select value={this.state.ttsVoice} onChange={this.onTtsVoicesChange.bind(this)}>{options}</select>
    );
  }

  render() {
    if(!this.state.expanded) {
      return (
        <div style={{ position: 'fixed', bottom: 10, right: 10, zIndex: 999 }}>
          <button onClick={() => this.setState({ expanded: true })} className="btn btn-secondary btn-sm">Settings</button>
        </div>
      );
    }

    return (
      <div style={{ position: 'fixed', bottom: 10, right: 10, zIndex: 999 }}>
        <div className={'card text-white mb-0 ' + (this.state.darkMode ? 'bg-secondary' : 'bg-dark')}>
          <div className="card-header">
            Settings
            <button type="button" className="close text-white" aria-label="Close" onClick={() => this.setState({ expanded: false })}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <ul className="list-unstyled mb-0">
              <li><label>Text-to-speech voice<br />{this.renderTtsVoices()}</label></li>
              <li><label><input name="autoPlayTts" type="checkbox" checked={this.state.autoPlayTts} onChange={this.onCheckboxChange.bind(this)} /> Auto-play audio</label></li>
              <li><label><input name="soundEffects" type="checkbox" checked={this.state.soundEffects} onChange={this.onCheckboxChange.bind(this)} /> Sound effects</label></li>
              <li><label><input name="darkMode" type="checkbox" checked={this.state.darkMode} onChange={this.onCheckboxChange.bind(this)} /> Dark mode</label></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
