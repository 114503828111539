import React from "react"
import PropTypes from "prop-types"
import ShareArticle from "./ShareArticle";

class DashboardArticleTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.article.tags || [],
      addVisible: false,
      newVisible: false,
      newTag: ''
    };
  }

  onAddClick(e) {
    e.preventDefault();
    this.setState({ addVisible: true });
  }

  renderAdd() {
    if(!this.state.addVisible || this.state.newVisible) {
      return null;
    }

    return (
      <>
      <select name="tag_select" onChange={(e) => this.addTag(e.target.value)} className="form-control mb-2" autoFocus={true}>
        <option></option>
        {this.props.availableTags.map((tag, i) => <option key={i} value={tag}>{tag}</option>)}
      </select>
        <div className="text-right">
          <button onClick={() => this.setState({ newVisible: true })} className="btn btn-sm btn-link">New</button>
          <button onClick={() => this.setState({ addVisible: false })} className="btn btn-sm btn-link ml-2">Cancel</button>
        </div>
      </>
    );
  }

  renderNew() {
    if(!this.state.newVisible) {
      return false;
    }

    return (
      <>
        <input className="form-control mb-2" name="new_tag" autoFocus={true} onKeyDown={(e) => e.which === 13 && this.saveNewTag()} onChange={(e) => this.setState({ newTag: e.target.value })} value={this.state.newTag} />
        <div className="text-right">
          <button onClick={() => this.saveNewTag()} className="btn btn-sm btn-primary">Save</button>
          <button onClick={() => this.setState({ newVisible: false })} className="btn btn-sm btn-link ml-2">Cancel</button>
        </div>
      </>
    );
  }

  removeTag(tag) {
    this.setState({ tags: this.state.tags.filter((t) => t !== tag) });
  }

  addTag(tag) {
    if(this.state.tags.includes(tag)) {
      this.setState({ addVisible: false });
      return false;
    }

    this.setState({
      tags: this.state.tags.concat([tag]),
      addVisible: false
    });
  }

  saveNewTag() {
    const tag = this.state.newTag;

    if(!this.props.availableTags.includes(tag)) {
      // add to all tags
      this.props.onTagCreated(tag);
    }

    this.setState({
      newVisible: false,
      newTag: ''
    });

    // add tag to article
    this.addTag(tag);
  }

  onRemoveClick(e, tag) {
    e.preventDefault();
    this.removeTag(tag);
  }

  render() {
    return (
      <div>
        <small>
          <span className="mr-1">Tags:</span>
          {this.state.tags.map((tag, i) => <a href="#" key={i} onClick={(e) => this.onRemoveClick(e, tag)} className="mr-2 btn btn-sm btn-primary" style={{ fontSize: '0.75rem', padding: '0rem 0.25rem' }}>{tag} <strong>&times;</strong></a>)}
          {!this.state.addVisible && <a href="#" onClick={(e) => this.onAddClick(e)}>Add</a>}
        </small>
        <div className="mt-2">
          {this.renderAdd()}
          {this.renderNew()}
        </div>
      </div>
    );
  }
}

class DashboardArticle extends React.Component {
  renderReview() {
    const { article } = this.props;

    if(article.completedSentenesCount && article.favoritedSentencesCount) {
      return (
        <li>Review: <a href={article.reviewCompletedPath}>Completed</a> / <a href={article.reviewFavoritedPath}>Favorited</a></li>
      );
    }
    if(article.completedSentenesCount) {
      return <li><a href={article.reviewCompletedPath}>Review completed sentences</a></li>;
    }
    if(article.favoritedSentencesCount) {
      return <li><a href={article.reviewFavoritedPath}>Review favorited sentences</a></li>;
    }
  }

  render() {
    const { article } = this.props;

    return (
      <div key={article.id} className={'article card ' + (article.complete ? 'border-success' : '')}>
        <div className="card-body">
          <div className="float-right">
            <ShareArticle
              accessControl={article.accessControl}
              name={article.name}
              updatePath={article.path}
              url={article.url}
            />
          </div>
          <h5 className="card-title">
            {/*
            <a href="#" className="float-right">
              <i className="fa fa-heart-o"></i>
            </a>
            */}
            <a href={article.path}>{article.name}</a>
          </h5>
          <ul className="card-text">
            <li>{article.wordsCount} words</li>
            <li>{article.completedSentenesCount}/{article.sentencesCount} sentences completed</li>
            {article.favoritedSentencesCount ? <li>{article.favoritedSentencesCount} sentences favorited <small><i className="fa fa-heart"></i></small></li> : null}
            {this.renderReview()}
          </ul>
        </div>
        <div className="card-footer text-muted">
          <div>
            <small>
              Added on {article.createdAt} / {article.sourceUrl && <span><a href={article.sourceUrl} target="_blank">Source</a> /</span>} {/*<a href="">Share</a> / */}<a href={article.editPath}>Edit</a> / <a href="javascript:void(0);" className={'delete' + (this.props.loading ? ' text-muted' : '')} onClick={() => this.props.deleteArticle()}>Delete</a>
            </small>
          </div>
          {/*<DashboardArticleTags
            article={article}
            availableTags={this.props.availableTags}
            onTagCreated={this.props.onTagCreated}
          />*/}
        </div>
      </div>
    );
  }
}

class DashboardArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: props.articles,
      totalArticlesCount: props.totalArticlesCount,
      page: 1,
      tags: props.tags || []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.order !== prevState.order || this.state.filter !== prevState.filter) {
      this.fetchArticles();
    }
  }

  fetchArticles() {
    this.setState({ loading: true });
    $.ajax({
      url: this.props.articlesPath,
      data: {
        filter: this.state.filter,
        order: this.state.order,
        page: this.state.page
      }
    })
      .done((data) => {
        this.setState({
          articles: data.articles,
          totalArticlesCount: data.totalArticlesCount,
          loading: false
        });
      })
      .fail(() => {
        alert('Oh no! There was an error getting the articles. Sorry about that. Please refresh the page and try again, and let us know if you see this message again.');
        this.setState({ loading: false });
      });
  }

  deleteArticle(article) {
    if(this.state.loading) {
      return false;
    }

    if(!confirm('Are you sure? This will delete the article and all its sentences, including favorited sentences. This cannot be undone.')) {
      return false;
    }

    this.setState({ loading: true });
    $.ajax({
      url: article.path,
      method: 'delete',
      dataType: 'json'
    })
      .done(() => {
        this.fetchArticles();
      })
      .fail(() => {
        console.log(arguments);
        alert('Oh no! There was an error deleting the article. Sorry about that. Please refresh and try again, and let us know if you see this message again.');
      });
  }

  renderArticles() {
    if(!this.state.articles || !this.state.articles.length) {
      return (
        <div className="text-center">
          <p>No challenges.</p>
        </div>
      );
    }

    const articles = this.state.articles.map((article, i) => (
      <DashboardArticle
        key={i}
        article={article}
        loading={this.state.loading}
        availableTags={this.state.tags}
        onTagCreated={(tag) => this.setState({ tags: this.state.tags.concat([tag]) })}
        deleteArticle={() => this.deleteArticle(article)}
      />
    ));

    return (
      <div className="card-columns">{articles}</div>
    );
  }

  renderStats() {
    if(!this.state.articles.length) {
      return null;
    }

    return (
      <div>
        Showing {this.state.articles.length} of {this.state.totalArticlesCount} challenges. {this.state.articles.length < this.state.totalArticlesCount ? <a href="javascript:void(0);" onClick={() => this.setState({ page: this.state.page + 1 }, () => this.fetchArticles())}>Load More</a> : null}
      </div>
    );
  }

  renderFilterControl() {
    return (
      <select name="filter" value={this.state.filter} disabled={this.state.loading} onChange={(e) => this.setState({ filter: e.target.value })}>
        <option>All</option>
        <option value="new">New</option>
        <option value="incomplete">Incomplete</option>
        <option value="complete">Complete</option>
        {/* <option value="favorited">Favorited</option> */}
        <option value="with_favorited_sentences">With favorited sentences</option>
      </select>
    );
  }

  renderTagControl() {
    return (
      <select name="tagged" value={this.state.tagged} disabled={this.state.loading} onChange={(e) => this.setState({ tagged: e.target.value })}>
        <option>Any</option>
        {this.state.tags.map((tag, i) => <option value={tag} key={i}>{tag}</option>)}
      </select>
    );
  }

  renderOrderControl() {
    return (
      <select name="order" value={this.state.order} disabled={this.state.loading} onChange={(e) => this.setState({ order: e.target.value })}>
        <option value="last_played">Last played</option>
        <option value="date_desc">Date added (newest first)</option>
        <option value="date_asc">Date added (oldest first)</option>
        <option value="az">Alphabetical (A-Z)</option>
        <option value="za">Alphabetical (Z-A)</option>
      </select>
    );
  }

  render() {
    return (
      <div>
        <ul className="list-inline mb-2">
          <li className="list-inline-item"><h2 className="d-inline align-middle">Challenges</h2></li>
          <li className="list-inline-item"><a href={this.props.newArticlePath} className="btn btn-sm btn-primary">New Challenge</a></li>
          {this.props.hasLibrary ? <li className="list-inline-item"><a href={this.props.libraryPath} className="btn btn-sm btn-primary">Library</a></li> : null}
          <li className="list-inline-item"><label>Show {this.renderFilterControl()}</label></li>
          {!!this.state.tags.length && <li className="list-inline-item"><label>Tag {this.renderTagControl()}</label></li>}
          <li className="list-inline-item"><label>Order {this.renderOrderControl()}</label></li>
        </ul>
        {this.renderArticles()}
        {this.renderStats()}
      </div>
    );
  }
}
 
export default DashboardArticles;
