import React from "react"
import Textarea from 'react-textarea-autosize';
import FadeOutNotification from './FadeOutNotification';

class Sentence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      text: props.text,
      translation: props.translation,
      note: props.note
    };
  }

  save() {
    this.setState({ updating: true });
    const state = this.state;
    const attrs = {
      text: state.text,
      translation: state.translation,
      note: state.note
    };
    $.ajax({
      method: this.state.id ? 'put' : 'post',
      url: this.state.id ? '/sentences/' + this.state.id : '/sentences',
      data: { article_id: this.props.articleId, sentence: attrs }
    })
      .done((data) => {
        if(data.success) {
          if(data.id) {
            attrs.id = data.id;
            this.setState({ id: data.id });
          }
          this.props.onUpdate && this.props.onUpdate(attrs);
        }
        else {
          alert(data.errors);
        }
      })
      .fail(() => {
        alert('Oh no! There was an error saving the sentence. Sorry about that. Please refresh the page and try again, and let us know if you see this error message again.');
      })
      .always(() => {
        this.setState({ updating: false })
      });
  }

  deleteSentence() {
    if(confirm('Are you sure you want to delete this sentence? This action cannot be undone.')) {
      this.props.onDelete && this.props.onDelete();
      if(this.state.id) {
        $.ajax({
          method: 'delete',
          url: '/sentences/' + this.state.id
        }).fail(() => {
          alert('Oh no! There was an error deleting that sentence. Sorry about that. Please refresh the page and try again, and let us know if you see this error message again.');
        });
      }
    }
  }

  isChanged() {
    const props = this.props;
    const state = this.state;
    return state.text !== props.text || state.translation !== props.translation || state.note !== props.note;
  }

  render() {
    return (
      <div className="sentence card mb-3">
        <div className="card-header">
          <strong>Sentence {this.props.index}</strong>
          {!this.state.id && <span className="badge badge-warning ml-3">New</span>}
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>Text</label>
            <Textarea className="form-control text" disabled={this.props.updating} value={this.state.text || ''} onChange={(e) => this.setState({ text: e.target.value })}></Textarea>
          </div>
          <div className="form-group">
            <label>Translation</label>
            <Textarea className="form-control translation" disabled={this.props.updating} value={this.state.translation || ''} onChange={(e) => this.setState({ translation: e.target.value })}></Textarea>
          </div>
          <div className="form-group">
            <label>Note</label>
            <Textarea className="form-control note" disabled={this.props.updating} value={this.state.note || ''} onChange={(e) => this.setState({ note: e.target.value })}></Textarea>
          </div>
          <div className="text-right">
            <button disabled={this.props.updating || !this.isChanged()} onClick={() => this.save()} className="save btn btn-sm btn-primary">Save</button>
            <button disabled={this.props.updating} onClick={() => this.deleteSentence()} className="delete btn btn-sm btn-link ml-2">Delete</button>
          </div>
        </div>
      </div>
    );
  }
}

class ArticleFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      url: props.url,
    };
  }

  isChanged() {
    const props = this.props;
    const state = this.state;
    return state.name !== props.name || state.url !== props.url;
  }

  save() {
    this.setState({ updating: true });
    const attrs = { name: this.state.name, url: this.state.url };
    $.ajax({
      url: this.props.updateArticlePath,
      method: 'put',
      data: { article: attrs }
    }).done((data) => {
      if(data.success) {
        this.props.onUpdate && this.props.onUpdate(attrs);
      }
      else {
        alert(data.errors);
      }
    }).fail(() => {
      alert('Oh no! There was an error updating the article. Sorry about that. Please refresh and try again, and let us know if you see this error message again.');
    }).always(() => {
      this.setState({ updating: false });
    });
  }

  render() {
    return (
      <div id="article-fields" className="mb-4">
        <div className="form-group">
          <label>Name</label>
          <Textarea id="article-name" className="form-control" disabled={this.props.updating} value={this.state.name || ''} onChange={(e) => this.setState({ name: e.target.value })}></Textarea>
        </div>
        <div className="form-group">
          <label>Source</label>
          <Textarea id="article-url" className="form-control" disabled={this.props.updating} value={this.state.url || ''} onChange={(e) => this.setState({ url: e.target.value })}></Textarea>
        </div>
        <div className="text-right">
          <button disabled={this.props.updating || !this.isChanged()} onClick={() => this.save()} className="btn btn-sm btn-primary">Save</button>
        </div>
      </div>
    );
  }
}

class ArticleEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      url: props.url,
      sentences: props.sentences
    };
  }

  renderArticleFields() {
    return (
      <ArticleFields
        name={this.state.name}
        url={this.state.url}
        updateArticlePath={this.props.updateArticlePath}
        onUpdate={(attrs) => {
          this.setState(attrs);
          this.showSavedNotification();
        }}
      />
    );
  }

  showSavedNotification() {
    this.savedNotification && this.savedNotification.show();
  }

  renderSavedNotification() {
    return (
      <FadeOutNotification ref={(el) => this.savedNotification = el}>
        <strong>Saved!</strong>
      </FadeOutNotification>
    );
  }

  renderSentences() {
    return this.state.sentences.map((s, i) => (
      <Sentence
        id={s.id}
        articleId={this.props.id}
        index={i + 1}
        text={s.text}
        translation={s.translation}
        note={s.note}
        key={i}
        onUpdate={(attrs) => {
          let sentences = this.state.sentences;
          for(var i = 0, n = sentences.length; i < n; i++) {
            if(sentences[i].id === s.id) {
              // attrs hash, loop through keys setting sentence values
              for(var k in attrs) {
                sentences[i][k] = attrs[k];
              }
              break;
            }
          }
          this.setState({ sentences: sentences });
          this.showSavedNotification();
        }}
        onDelete={() => {
          this.setState({ sentences: this.state.sentences.filter((sentence) => sentence.id !== s.id) });
          this.showSavedNotification();
        }}
      />
    ));
  }

  onAddSentenceBtnClick() {
    if(this.state.sentences.filter((s) => !s.id).length) {
      return alert('Save existing new sentence before adding another!');
    }
    this.setState({ sentences: this.state.sentences.concat([{}]) })
  }

  renderAddSentenceBtn() {
    return (
      <button id="add-sentence" className="btn btn-lg btn-primary" onClick={() => this.onAddSentenceBtnClick()}>Add Sentence</button>
    );
  }

  render() {
    return (
      // article name
      // article url
      //
      // sentences
      // - text
      // - translation
      // - note
      // - delete
      //
      // add sentences
      <div className="mb-5">
        {this.renderArticleFields()}
        {this.renderSentences()}
        {this.renderAddSentenceBtn()}
        {this.renderSavedNotification()}
      </div>
    );
  }
}

export default ArticleEditor;
