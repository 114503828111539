import React from "react";

export default class FadeOutNotification extends React.Component {
  show() {
    this.notification && $(this.notification).finish().show().delay(2000).fadeOut();
  }

  render() {
    return (
      <div ref={(el) => this.notification = el}style={{ display: 'none', position: 'fixed', bottom: this.props.bottom || 15, right: 15 }}>
        <div className={'alert alert-' + (this.props.theme || 'success') + ' d-inline-block px-5'}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
