import store from './store';

const populateVoices = () => {
  if(typeof speechSynthesis === 'undefined') {
    return;
  }
  if(speechSynthesis.onvoiceschanged !== undefined) {
    speechSynthesis.onvoiceschanged = populateVoices;
  }
  store.dispatch({ type: 'UPDATE_VOICES', voices: speechSynthesis.getVoices() });
};

export default populateVoices;
