import React from "react"

class TranslationsFormGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addTranslations: props.addTranslations === false ? 'false' : 'true' // default true
    };
  }

  render() {
    return (
      <div className="form-group">
        <div className="form-check form-check-inline">
          <input className="form-check-input" id="add-translations-true" type="radio" name="add_translations" value="true" checked={this.state.addTranslations === 'true'} onChange={(e) => this.setState({ addTranslations: e.target.value })} />
          <label className="form-check-label" htmlFor="add-translations-true">Add machine translations</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" id="add-translations-false" type="radio" name="add_translations" value="false" checked={this.state.addTranslations === 'false'} onChange={(e) => this.setState({ addTranslations: e.target.value })} />
          <label className="form-check-label" htmlFor="add-translations-false">I'll read through and add my own translations</label>
        </div>
      </div>
    );
  }
}

export default TranslationsFormGroup;

