import React from "react";
import Modal from "./Modal";

function AccessControlIcon(props) {
  let icon = null;
  switch(props.accessControl) {
    case 'public':
      icon = 'unlock';
      break;
    case 'link':
      icon = 'link';
      break;
    case 'off':
      icon = 'lock'
      break;
    default:
      throw new Error("unrecognized accessControl");
  }

  return (
    <i className={'fa fa-' + icon + ' ' + (props.className || '')} aria-hidden="true" />
  );
}

class ShareArticleControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accessControl: props.accessControl || 'off',
      errorMessage: '',
      loading: false,
      shareWith: '',
    };
  }

  renderSharingControls() {
    if(this.state.accessControl === 'off') {
      return null;
    }

    return (
      <>
        <hr />
        <div className="form-group">
          <label>Shareable URL:</label>
          <input disabled={this.state.loading} type="text" className="form-control" readOnly={true} onFocus={(e) => e.target.select()} value={this.props.url} />
        </div>
        {/*
        <div className="form-group">
          <label>Share with specific people:</label>
          <input disabled={this.state.loading} type="text" className="form-control" placeholder="Enter names or email addresses..." value={this.state.shareWith} onChange={(e) => this.setState({ shareWith: e.target.value })} />
          <div className="text-right mt-1">
            <button className="btn btn-primary btn-sm">Send</button>
          </div>
        </div>
        */}
      </>
    );
  }

  onAccessControlChange(e) {
    this.setState({ loading: true, errorMessage: '' });

    const accessControl = e.target.value;

    $.ajax({
      method: 'put',
      url: this.props.updatePath,
      data: { article: { access_control: accessControl } }
    })
      .done((status) => {
        if(!status.success) {
          this.setState({ errorMessage: status.errors, loading: false });
          return true;
        }

        this.setState({
          accessControl,
          loading: false
        }, () => {
          this.props.onAccessControlChange(accessControl);
        });
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Oh no! There was an error updating. Please try again and let us know if you see this error message again.');
      });
  }

  renderErrorMessage() {
    if(this.state.errorMessage) {
      return (
        <p className="alert alert-danger">{this.state.errorMessage}</p>
      );
    }
  }

  render() {
    const { accessControl } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <p>Please only share articles that you own or that are in the public domain.</p>
        {this.renderErrorMessage()}
        <div className="ml-4">
          <div className="form-group">
            <div className="form-check">
              <input disabled={this.state.loading} className="form-check-input" type="radio" name="access-control" id="access-control-public" value="public" checked={accessControl === 'public'} onChange={(e) => this.onAccessControlChange(e)} />
              <label class="form-check-label" htmlFor="access-control-public"><AccessControlIcon accessControl="public" className="mx-1" /> <strong>Public</strong></label>
            </div>
            Anyone can import this article via the library.
          </div>
          <div className="form-group">
            <div className="form-check">
              <input disabled={this.state.loading} className="form-check-input" type="radio" name="access-control" id="access-control-link" value="link" checked={accessControl === 'link'} onChange={(e) => this.onAccessControlChange(e)} />
              <label class="form-check-label" htmlFor="access-control-link"><AccessControlIcon accessControl="link" className="mx-1" /> <strong>Link</strong></label>
            </div>
            Only people with the link can import this article.
          </div>
          <div className="form-group">
            <div className="form-check">
              <input disabled={this.state.loading} className="form-check-input" type="radio" name="access-control" id="access-control-off" value="off" checked={accessControl === 'off'} onChange={(e) => this.onAccessControlChange(e)} />
              <label class="form-check-label" htmlFor="access-control-off"><AccessControlIcon accessControl="off" className="mx-1" /> <strong>Off</strong></label>
            </div>
          </div>
        </div>
        {this.renderSharingControls()}
      </div>
    );
  }
}

export default class ShareArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      accessControl: props.accessControl
    };
  }

  renderModal() {
    if(!this.state.modalVisible) {
      return null;
    }

    return (
      <Modal
        onHidden={() => this.setState({ modalVisible: false })}
        title="Share"
        footer={<button className="btn btn-outline-primary btn-sm" data-dismiss="modal">Close</button>}
      >
        <ShareArticleControls
          accessControl={this.state.accessControl}
          name={this.props.name}
          updatePath={this.props.updatePath}
          url={this.props.url}
          onAccessControlChange={(accessControl) => this.setState({ accessControl })}
        />
      </Modal>
    );
  }

  render() {
    const { accessControl } = this.state;

    return (
      <>
        <button class="btn btn-sm btn-outline-primary" onClick={() => this.setState({ modalVisible: true })}>
          <AccessControlIcon accessControl={accessControl || 'off'} />{this.props.size === 'large' ? ' Share' : ''}
        </button>
        {this.renderModal()}
      </>
    );
  }
}
