import React from "react";
import Textarea from 'react-textarea-autosize';

class PhraseEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      text: props.text.replace(/^\s+|\s+$/g, ''),
      translation: props.translation,
      note: props.note,
      path: props.path,
      loading: !!props.id
    };
  }

  componentDidMount() {
    if(!this.state.id) {
      this.getPhraseData();
    }
    else {
      this.setState({ loading: false });
    }
  }

  getPhraseData() {
    $.ajax({
      url: this.props.phrasesPath + '/data',
      method: 'post',
      data: { text: this.state.text }
    })
      .done((data) => {
        this.setState({
          id: data.id,
          translation: data.translation,
          note: data.note,
          path: data.path,
          loading: false
        });
      })
      .fail(() => {
        alert('Error loading phrase data!');
      });
  }

  renderControls() {
    if(this.state.id) {
      return (
        <>
          <small>In your deck <i className="fa fa-check"></i></small>
          <button disabled={this.state.loading || this.state.deleted} className="btn btn-primary mx-2" onClick={() => this.updatePhrase()}>Update</button>
          <button disabled={this.state.loading || this.state.deleted} className="btn btn-sm btn-light delete" onClick={() => this.onDeleteClick()}><i className="fa fa-trash"></i></button>
        </>
      );
    }

    return (
      <button className="btn btn-primary" disabled={!!this.props.demo || this.state.loading} onClick={() => this.savePhrase()}>Save to Phrase Deck</button>
    );
  }

  onDeleteClick() {
    if(confirm('Are you sure? This can\'t be undone.')) {
      this.deletePhrase();
    }
  }

  deletePhrase() {
    this.setState({ loading: true });
    $.ajax({
      url: this.props.phrasesPath + '/' + this.state.id,
      method: 'delete'
    })
      .done((data) => {
        this.setState({ loading: false, deleted: true }, () => this.props.onDelete && this.props.onDelete(this.state.id));
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Error saving phrase!');
      });
  }

  getPhraseFromState() {
    return {
      id: this.state.id,
      text: this.state.text,
      translation: this.state.translation,
      note: this.state.note
    };
  }

  savePhrase() {
    this.setState({ loading: true });
    $.ajax({
      url: this.props.phrasesPath,
      method: 'post',
      data: {
        sentence_id: this.props.sentenceId,
        text: this.state.text,
        translation: this.state.translation,
        note: this.state.note
      }
    })
      .done((data) => {
        if(data.errors) {
          this.setState({ loading: false });
          alert('Error saving phrase! ' + data.errors);
          return false;
        }
        this.setState({
          loading: false,
          id: data.id,
          path: data.path
        }, () => this.props.onSave && this.props.onSave(this.getPhraseFromState()));
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Error saving phrase!');
      });
  }

  updatePhrase() {
    this.setState({ loading: true });
    $.ajax({
      url: this.state.path,
      method: 'put',
      data: {
        translation: this.state.translation,
        note: this.state.note
      }
    })
      .done(() => {
        this.setState({ loading: false }, () => this.props.onSave && this.props.onSave(this.getPhraseFromState()));
      })
      .fail(() => {
        this.setState({ loading: false });
        alert('Error updating phrase!');
      });
  }

  renderLinks() {
    return (this.props.links || []).map((url) => (
      <small className="mr-2" key={url[1]}><a href={url[1].replace('QUERY', encodeURI(this.state.text))} target="_blank">{url[0]}</a></small>
    ));
  }

  render() {
    let hintMessage = 'Save words and phrases to your Phrase Deck to review them later via your Dashboard.';
    if(this.props.demo === 'shared') {
      hintMessage = 'Import this article to save words and phrases to your Phrase Deck!';
    }
    if(this.props.demo === 'basic') {
      hintMessage = 'Sign up or sign in to save words and phrases to your Phrase Deck!';
    }
    const hint = <p><small>{hintMessage}</small></p>;

    // copy button
    // userful links
    // social links
    // post to forum link

    return (
      <div className={'phrase-editor' + (this.state.deleted ? ' deleted' : '')}>
        <p style={{ fontSize: '1.25em' }} className="text mb-0"><strong>{this.state.text}</strong></p>
        <div className="mb-2">{this.renderLinks()}</div>
        <div className="form-group">
          <Textarea className="form-control mb-2" placeholder="Translation loading..." name="translation" disabled={this.props.loading || this.state.deleted} value={this.state.translation || ''} onChange={(e) => this.setState({ translation: e.target.value })}></Textarea>
        </div>
        <div className="form-group">
          <Textarea className="form-control mb-2" placeholder="Notes" name="notes" disabled={this.props.loading || this.state.deleted} value={this.state.note || ''} onChange={(e) => this.setState({ note: e.target.value })}></Textarea>
        </div>
        {this.props.includeHint && hint}
        <div className="text-right">{this.renderControls()}</div>
      </div>
    );
  }
}

export default PhraseEditor;
