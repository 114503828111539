import React from "react"
import ConfettiModal from './ConfettiModal';

export default class SubscribeSuccessModal extends React.Component {
  render() {
    return (
      <ConfettiModal closeBtn={false} static={true}>
        <div className="text-center">
          <p className="lead alert alert-success">Success!</p>
          <p>
            Welcome to
            <br />
            <span className="logo" style={{ fontSize: '2em' }}>The Great Translation Game</span>
          </p>
          <p className="display-4">&#x1F389;</p>
          <p>Please be sure to confirm your email.</p>
          <p>Select your language to get started.</p>
          <a href={this.props.languagesPath} className="btn btn-primary btn-lg">Go!</a>
        </div>
      </ConfettiModal>
    );
  }
}
