import React from "react"
import ConfettiGenerator from "confetti-js";

class ArticleCompleteNotificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      success: false,
    };
  }

  onSubmit(e) {
    e.preventDefault();
    this.sendNotification();
  }

  sendNotification() {
    this.setState({ loading: true, success: null });
    $.ajax({
      url: '',
      data: {
        article_complete_notification: {
          to_email: this.state.email,
          from_name: this.state.name
        }
      }
    })
      .done(() => {
        this.setState({ success: true, loading: false });
      })
      .fail(() => {
        alert('Oh no! There was an error sending the notification. Sorry about that. Please try again and let us know if you see this message again.');
        this.setState({ success: false, loading: false });
      });
  }

  render() {
    return (
      <form className="my-3 text-left" onSubmit={(e) => this.onSubmit(e)}>
        <div className="form-group">
          <p className="text-center">Let someone know you know completed this challenge</p>
          {this.state.success && <div className="alert alert-success">Sent!</div>}
          <label for="article_complete_notification[from_name]">Your name</label>
          <input disabled={this.state.loading} type="text" className="form-control" name="article_complete_notification[email]" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
          <label for="article_complete_notification[to_email]">Recipient's email</label>
          <input disabled={this.state.loading} type="text" className="form-control" name="article_complete_notification[email]" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
          <div className="text-right mt-2"><button disabled={this.state.loading} className="btn btn-primary">Send</button></div>
        </div>
      </form>
    );
  }
}

export default class ArticleCompleteModal extends React.Component {
  componentDidMount() {
    const confettiSettings = {"target":"confetti-holder","max":"80","size":"1","animate":true,"props":["circle","square","triangle","line"],"colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],"clock":"25","rotate":false,"width":"","height":""}
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    $('#article-complete-modal')
      .modal('show')
      .on('hidden.bs.modal', () => {
        this.props.onHidden();
      });

    window.addthis &&
      window.addthis.layers &&
      window.addthis.layers.refresh &&
      window.addthis.layers.refresh();
  }

  componentWillUnmount() {
    clearTimeout(this.loopTimeout);
  }

  renderBody() {
    return (
      <div className="mt-3">
        <div className="display-4">&#x1F389;</div>
        {this.renderShareThis()}
        {this.renderEmailForm()}
        <p>&#x1F680; Keep it up! &#x1F680;</p>
        {this.renderButtons()}
      </div>
    );
  }

  renderEmailForm() {
    // return (
    //   <ArticleCompleteNotificationForm />
    // );
  }

  renderButtons() {
    const { demo, newArticlePath } = this.props;

    const button1 = demo ? <a href="/" className="btn btn-block btn-primary">Learn More</a> :
      <a href="/dashboard" className="btn btn-block btn-outline-primary">Dashboard</a>;
    const button2 = demo ?  <a href="/users/sign_up" className="btn btn-block btn-primary">Sign Up</a> :
      <a href={newArticlePath} className="btn btn-block btn-primary">New Challenge</a>;
    const button3 = <button type="button" className="btn btn-block btn-light" data-dismiss="modal" aria-label="Close">Close</button>;

    return (
      <div className="row">
        <div className="col">{button1}</div>
        <div className="col">{button2}</div>
        <div className="col">{button3}</div>
      </div>
    );
  }

  renderShareThis() {
    return (
      <div className="my-3">
        <div>Tell the world!</div>
        <div className="addthis_inline_share_toolbox" data-url="https://www.thegreattranslationgame.com" data-title={'I just completed a challenge learning ' + this.props.languageEnglishName + ' playing The Great Translation Game!'}></div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <canvas id="confetti-holder" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}></canvas>
        <div id="article-complete-modal" className="modal fade">
          <div className="modal-dialog" style={{ marginTop: '4rem' }}>
            <div className="modal-content" style={{ background: this.props.darkMode ? '#000' : null }}>
              <div className="modal-body text-center">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="display-4" style={{ fontSize: '2.5rem' }}>Challenge Complete!</div>
                {this.renderBody()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
