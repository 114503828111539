import React from "react"
import ConfettiGenerator from "confetti-js";

export default class ConfettiModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confettiVisible: false
    };
  }

  componentDidMount() {
    const confettiSettings = {"target":"confetti-holder","max":"80","size":"1","animate":true,"props":["circle","square","triangle","line"],"colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],"clock":"25","rotate":false,"width":"","height":""}

    $('#confetti-modal')
      .on('show.bs.modal', () => {
        this.setState({ confettiVisible: true }, () => {
          const confetti = new ConfettiGenerator(confettiSettings);
          confetti.render();
        });
      })
      .on('hidden.bs.modal', () => {
        this.setState({ confettiVisible: false });
        this.props.onHidden && this.props.onHidden();
      })
      .modal('show');
  }

  renderCloseBtn() {
    if(this.props.closeBtn === false) {
      return null;
    }

    return (
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    );
  }

  render() {
    return (
      <div>
        {this.state.confettiVisible && <canvas id="confetti-holder" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}></canvas>}
        <div id="confetti-modal" className="modal fade" data-backdrop={this.props.static ? 'static' : 'true'}>
          <div className="modal-dialog" style={{ marginTop: '4rem' }}>
            <div className="modal-content" style={{ background: this.props.darkMode ? '#000' : null }}>
              <div className="modal-body text-center">
                {this.renderCloseBtn()}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

