import React from "react"
import PropTypes from "prop-types"
import Sentence from './Sentence';
import GameSettings from './GameSettings';
import defaultGameSettings from '../defaultGameSettings';
import GameStatus from './GameStatus';
import LeveledUpModal from './LeveledUpModal';
import ArticleCompleteModal from './ArticleCompleteModal';
import FadeOutNotification from './FadeOutNotification';

class Article extends React.Component {
  static defaultProps = {
    gameSettings: defaultGameSettings
  }

  constructor(props) {
    super(props);
    this.sentenceRefs = {};
    this.state = {
      sentences: props.sentences,
      completeSentenceIds: props.sentences.filter((sentence) => sentence.complete).map((sentence) => sentence.id),
      wordsPlayedToday: props.wordsPlayedToday,
      currentRank: props.currentRank,
      wordsToNextLevel: props.wordsToNextLevel,
      wordsToNextRank: props.wordsToNextRank,
      gameSettings: props.gameSettings || defaultGameSettings,
      onlyFavorites: false,
      showLeveledUpModal: false,
      showCompleteModal: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const wasComplete = (prevState.sentences.length === prevState.completeSentenceIds.length);
    const isComplete = (this.state.sentences.length === this.state.completeSentenceIds.length);

    if(!wasComplete && isComplete) {
      this.setState({ showCompleteModal: true });
    }
  }

  onSentenceReset(sentenceId) {
    const index = this.state.completeSentenceIds.indexOf(sentenceId);
    if(index < 0) {
      return false;
    }
    this.state.completeSentenceIds.splice(index, 1);
    this.setState({
      completeSentenceIds: this.state.completeSentenceIds
    });
  }

  onSentenceComplete(sentenceId) {
    this.setState({
      completeSentenceIds: this.state.completeSentenceIds.concat([sentenceId])
    });
  }

  onProgressUpdate(data) {
    this.setState({
      wordsPlayedToday: data.wordsPlayedToday,
      currentRank: data.currentRank,
      wordsToNextLevel: data.wordsToNextLevel,
      wordsToNextRank: data.wordsToNextRank,
      showLeveledUpModal: data.leveledUp
    });
  }

  renderDemoAlert() {
    return (
      <FadeOutNotification ref={(el) => this.demoAlert = el} theme="primary" bottom={35}>
        <strong><a href="/users/sign_up">Sign up</a></strong> or <strong><a href="/users/sign_in">sign in</a></strong> to save your changes!
      </FadeOutNotification>
    );
  }

  renderSentences() {
    let sentences = this.state.sentences;
    if(this.state.onlyFavorites) {
      sentences = sentences.filter((s) => s.favorited);
    }
    return sentences.map((sentence, i) => {
      return (
        <Sentence 
          completions={sentence.completions}
          demo={this.props.demo}
          demoAlert={() => this.demoAlert && this.demoAlert.show()}
          key={sentence.id}
          ref={(el) => this.sentenceRefs[sentence.id] = el}
          sentenceId={sentence.id}
          text={sentence.text}
          translation={sentence.translation}
          translationAccepted={sentence.translationAccepted}
          wordCount={sentence.wordCount}
          complete={sentence.complete}
          twoLetterLanguageCode={sentence.twoLetterLanguageCode}
          voiceLocale={sentence.voiceLocale}
          onReset={this.onSentenceReset.bind(this)}
          onComplete={this.onSentenceComplete.bind(this)}
          onProgressUpdate={this.onProgressUpdate.bind(this)}
          gameSettings={this.state.gameSettings}
          favorited={sentence.favorited}
          favoriteable={this.props.sentencesFavoriteable}
          note={sentence.note}
          noteable={this.props.sentencesNoteable}
          deleteable={this.props.sentencesDeleteable}
          phrasesPath={this.props.phrasesPath}
          phraseEditorLinks={this.props.phraseEditorLinks}
          phrases={sentence.phrases}
          onDelete={() => this.setState({
            sentences: this.state.sentences.filter((s) => s.id !== sentence.id),
            completeSentenceIds: this.state.completeSentenceIds.filter((id) => id !== sentence.id)
          })}
          onUpdate={(attrs) => {
            let sentences = this.state.sentences;
            for(var i = 0, n = sentences.length; i < n; i++) {
              if(sentences[i].id === sentence.id) {
                // attrs hash, loop through keys setting sentence values
                for(var k in attrs) {
                  sentences[i][k] = attrs[k];
                }
                break;
              }
            }
            this.setState({ sentences: sentences });
          }}
          editableTranslation={this.props.editableTranslations}
        />
      );
    });
  }

  renderStatus() {
    if(!!this.props.demo || this.props.gameStatus === false) {
      return null;
    }

    return (
      <GameStatus
        sentencesCompletedCount={this.state.completeSentenceIds.length}
        totalSentencesCount={this.state.sentences.length}
        wordsPlayedToday={this.state.wordsPlayedToday}
        currentRank={this.state.currentRank}
        wordsToNextLevel={this.state.wordsToNextLevel}
        wordsToNextRank={this.state.wordsToNextRank}
      />
    );
    // return (
    //   <div className="bg-dark text-white" style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1450 }}>
    //     <div className="container-fluid position-relative">
    //       <div className="logo d-inline pl-3" style={{ position: 'absolute', left: 0 }}>
    //         <a href="/" className="d-none d-sm-inline text-white text-decoration-none">The Great Translation Game</a>
    //         <span className="d-sm-none">TG<sup>2</sup></span>
    //       </div>
    //       <div className="text-center">
    //         <ul className="list-inline mb-0">
    //           <li className="list-inline-item"><small className="text-uppercase">Sentences:</small> <strong>{this.state.completeSentenceIds.length}/{this.state.sentences.length}</strong></li>
    //           <li className="list-inline-item"><small className="text-uppercase">Words today:</small> <strong>{this.state.wordsPlayedToday}</strong></li>
    //           <li className="list-inline-item"><small className="text-uppercase">Rank:</small> <strong>{this.state.currentRank}</strong></li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className="progress" style={{ height: 2 }}>
    //       <div className="progress-bar bg-success" role="progressbar" style={{ width: (this.state.completeSentenceIds.length / this.state.sentences.length * 100) + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    //     </div>
    //   </div>
    // );
  }

  renderSettings() {
    return (
      <GameSettings
        gameSettings={this.state.gameSettings}
        twoLetterLanguageCode={this.props.twoLetterLanguageCode}
        onUpdate={(gameSettings) => this.setState({ gameSettings })}
        updateGameSettingsPath={this.props.updateGameSettingsPath}
      />
    );
  }

  onResetAllClick() {
    if(confirm("Are you sure you want to reset all sentences?")) {
      this.resetAllSentences();
    }
  }

  resetAllSentences() {
    this.state.sentences.forEach((s) => this.sentenceRefs[s.id] && this.sentenceRefs[s.id].onResetClick());
  }

  onPlayAllClick() {
    const filtered = this.state.sentences.filter((s) => !this.state.completeSentenceIds.includes(s.id)).reverse();
    filtered.forEach((s, index) => {
        if(!this.sentenceRefs[s.id] || this.sentenceRefs[s.id].state.playing) {
          return null;
        }
        this.sentenceRefs[s.id].play(() => {
          const scrollTop = this.sentenceRefs[s.id].getScrollTop();

          if(index === (filtered.length - 1) && scrollTop) {
            $('html, body').animate({ scrollTop: scrollTop - 36 }, () => { // 36 for banner height
              this.sentenceRefs[s.id].inputRef.focus();
            });
          }
        });
      });
  }

  renderControls() {
    let playAllBtn = null;
    let resetAllBtn = null;
    // return (
    //   <div className="text-right mb-2 controls">
    //     <div className="btn-group">
    //       <button type="button" className="btn btn-sm btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    //         Right-aligned menu
    //       </button>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <button className="dropdown-item" type="button">Play All</button>
    //         <button className="dropdown-item" type="button">Reset All</button>
    //         <button className="dropdown-item" type="button">Only Favorites</button>
    //       </div>
    //     </div>
    //   </div>
    // );
    if(this.state.sentences.length !== this.state.completeSentenceIds.length) {
      playAllBtn = <button className="btn btn-sm btn-secondary ml-2" onClick={() => this.onPlayAllClick()}>Play All</button>;
    }
    if(this.state.completeSentenceIds.length) {
      resetAllBtn = <button className="btn btn-sm btn-secondary ml-2" onClick={() => this.onResetAllClick()}>Reset All</button>;
    }
    return (
      <div className="text-right mb-2 controls">
        {playAllBtn}
        {resetAllBtn}
        {!this.props.demo && <button className="btn btn-sm btn-secondary ml-2" onClick={() => this.setState({ onlyFavorites: !this.state.onlyFavorites })}>{this.state.onlyFavorites ? 'All Sentences' : 'Only Favorites'}</button>}
      </div>
    );
  }

  renderCompleteModal() {
    if(this.state.showCompleteModal) {
      return (
        <ArticleCompleteModal
          darkMode={this.state.gameSettings.darkMode}
          demo={this.props.demo}
          newArticlePath={this.props.newArticlePath}
          articleCompleteNotificationPath={this.props.articleCompleteNotificationPath}
          onHidden={() => this.setState({ showCompleteModal: false })}
        />
      );
    }
  }

  renderLeveledUpModal() {
    if(this.state.showLeveledUpModal) {
      return (
        <LeveledUpModal
          level={this.state.showLeveledUpModal}
          darkMode={this.state.gameSettings.darkMode}
          languageEnglishName={this.props.languageEnglishName}
          onHidden={() => this.setState({ showLeveledUpModal: false })}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderStatus()}
        {this.renderControls()}
        {this.renderSentences()}
        {this.renderSettings()}
        {this.renderLeveledUpModal()}
        {this.renderCompleteModal()}
        {!!this.props.demo && this.renderDemoAlert()}
      </div>
    );
  }
}

export default Article;
