import React from "react";

import Sentence from "./Sentence";

export default class Demo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLanguageEnglishName: 'German',
      demoSentenceIndex: 0
    };
  }

  renderLanguageOptions() {
    return this.props.languages.map((l) => (
      <option key={l.englishName} value={l.englishName}>{l.englishName}</option>
    ));
  }

  renderSentence() {
    const { currentLanguageEnglishName, demoSentenceIndex } = this.state;
    const language = this.props.languages.filter((l) => l.englishName === currentLanguageEnglishName)[0];

    const sentence = language.demoSentences[demoSentenceIndex];

    if(!sentence) {
      return (
        <p className="display-4 text-center">
          Sign up or sign in to keep playing!
        </p>
      );
    }

    const sentenceProps = {
      demo: 'basic',
      editableTranslation: true,
      favoriteable: true,
      key: language.englishName + demoSentenceIndex,
      noteable: true,
      // onComplete: () => this.setState({ demoSentenceIndex: demoSentenceIndex + 1 }),
      phrasesPath: language.phrasesPath,
      phraseEditorLinks: language.phraseEditorLinks,
      text: sentence.text,
      translation: sentence.translation,
      twoLetterLanguageCode: language.twoLetterCode,
      voiceLocale: language.voiceLocale,
      wordCount: sentence.wordsCount
    };

    return (
      <div>
        <Sentence {...sentenceProps} />
        <div style={{ marginTop: '-1rem' }}>
          <div className="float-right">
            <small style={{ verticalAlign: 'middle' }}><a href={language.demoSentencesSourceUrl} target="_blank">Source</a> / </small>
            <small style={{ verticalAlign: 'middle' }}>English not your first language? No problem - choose a different base language when you sign up.</small>
          </div>
          {/*<button className="btn btn-link btn-sm">Skip</button>*/}
        </div>
      </div>
    );
  }


  render() {
    const { currentLanguageEnglishName } = this.state;
    const language = this.props.languages.filter((l) => l.englishName === currentLanguageEnglishName);

    return (
      <div>
        <form className="form-inline mb-1">
          <label className="mr-1">I'm learning:</label>
          <select
            className="form-control"
            onChange={(e) => this.setState({ currentLanguageEnglishName: e.target.value, demoSentenceIndex: 0 })}
            value={currentLanguageEnglishName}
          >
            {this.renderLanguageOptions()}
          </select>
        </form>
        {this.renderSentence()}
      </div>
    );
  }
}
