import React from "react";

export default class DailyReminder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reminderAt: props.reminderAt
    };
  }

  renderControl() {
    const reminderAt = this.state.reminderAt;
    const content = reminderAt == null || reminderAt === '' ?
      'Daily Reminder'
      : 'Reminder ' + this.hourToReadableTime(reminderAt);
    return (
      <button className="btn btn-primary btn-sm" data-toggle="modal" data-target="#daily-reminder-modal">{content}</button>
    );
  }

  onChange(e) {
    this.setState({ reminderAt: e.target.value, saving: true }, () => {
      $.ajax({
        url: this.props.updatePath,
        method: 'post',
        data: { reminder_at: this.state.reminderAt }
      })
        .fail(() => {
          alert('Error updating daily reminder! Sorry about that. Please try again and let us know if you this error message again.');
        })
        .always(() => {
          this.setState({ saving: false });
        });
    });
  }

  hourToReadableTime(i) {
    return (i % 12 === 0 ? '12:00' : ((i % 12) + ':00')) + ' ' + ((i / 12 >= 1) ? 'PM' : 'AM');
  }

  renderModal() {
    let timeOptions = [];
    for(var i = 0, n = 24; i < n; i++) {
      timeOptions.push(<option key={i} value={i}>{this.hourToReadableTime(i)}</option>);
    }
    return (
      <div id="daily-reminder-modal" className="modal fade">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Daily Reminder</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Simply select a time and we'll send you a reminder email to play The Great Translation Game in {this.props.language} daily.</p>
              <p><strong>Keep your streak alive!</strong></p>
              <div className="form-group">
                Send reminder at
                <select disabled={this.state.saving} className="form-control" value={this.state.reminderAt || ''} onChange={this.onChange.bind(this)}>
                  <option value="">No reminder</option>
                  {timeOptions}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal">Done</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderControl()}
        {this.renderModal()}
      </>
    );
  }
}
