import React from "react"
import PropTypes from "prop-types"
import Textarea from 'react-textarea-autosize';

class WikipediaImporter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: ''
    };
  }

  onFormSubmit(e) {
    e && e.preventDefault();
    this.setState({ loading: true });
    $.ajax({
      url: this.props.queryPath,
      method: 'post',
      data: { query: this.state.query }
    })
      .done((data) => {
        this.setState({
          loading: false,
          notFound: data.notFound,
          name: data.title,
          content: data.content,
          selected: {},
          id: new Date().getTime().toString()
        });
      })
      .fail(() => {
        alert('Error');
        this.setState({ loading: false });
        console.log(arguments);
      });
  }

  renderForm() {
    return (
      <form className="mb-3" onSubmit={this.onFormSubmit.bind(this)}>
        <div className="form-group">
          <label>Wikipedia page title or url (for example, <strong><a href="javascript:void(0);" onClick={() => this.setState({ query: "Harry Potter" }, () => this.onFormSubmit())}>Harry Potter</a></strong> should be available for most languages)</label>
          <input type="text" id="query" className="form-control" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} disabled={this.state.loading} />
        </div>
        <button className="btn btn-primary" disabled={this.state.loading}>Submit</button>
      </form>
    );
  }

  renderLoading() {
    if(this.state.loading) {
      return <p>Loading...</p>;
    }
  }

  toggleSelected(index) {
    let selected = this.state.selected;
    selected[index] = !selected[index];
    this.setState({ selected: selected });
  }

  import() {
    if(!this.state.name) {
      alert('Please enter a name for the article first.');
      return false;
    }

    let sentences = [];
    const selected = this.state.selected;
    const content = this.state.content;
    let count = 0;
    for(let k in selected) {
      if(selected[k]) {
        sentences = sentences.concat(content[k].sentences);
      }
    }

    if(!sentences.length) {
      alert('Please select the sections you\'d like to import first.');
      return false;
    }

    $('#loading-modal').modal('show');

    $.ajax({
      method: 'post',
      url: this.props.importPath,
      data: {
        article: {
          name: this.state.name,
          url: this.state.url,
          text: sentences.join("\n")
        }
      }
    })
      .done((data) => {
        if(data.url) {
          window.location = data.url;
        }
        else {
          alert('Oh no! There was an error importing the article. ' + data.errors);
          $('#loading-modal').modal('hide');
        }
      })
      .fail(() => {
        alert('Oh no! There was an error importing the article. Sorry about that. Please refresh the page and try again, and let us know if you see this message again.');
      });
  }

  renderResults() {
    if(this.state.loading) {
      return null;
    }

    if(this.state.notFound) {
      return <p>No page found.</p>;
    }

    if(!this.state.content) {
      return null;
    }

    const content = this.state.content;
    const baseKey = this.state.id;
    let parts = [];
    for(let i = 0, n = content.length, followedBySentences, selected; i < n; i++) {
      followedBySentences = content[i + 1] && content[i + 1].sentences && content[i + 1].sentences.length;

      if(!content[i].heading || !followedBySentences) {
        continue;
      }

      selected = this.state.selected[i + 1];

      parts.push(
        <div key={baseKey + i}>
          <button className={'include btn btn-sm mr-2 btn-' + (selected ? '' : 'outline-') + 'primary'} onClick={() => this.toggleSelected(i + 1)}> 
            <i className={'fa fa-' + (selected ? 'check-' : '') + 'square-o'}></i> Include
          </button>
          <h2 className="d-inline align-middle">
            {content[i].heading} <small className="text-muted">{content[i + 1].sentences.length} sentences</small>
          </h2>
        </div>
      );
      parts.push(
        <ol key={baseKey + i + 'ol'}>
          {content[i + 1].sentences.map((s, j) => (
            <li key={baseKey + j}>{s}</li>
          ))}
        </ol>
      );
    }

    const selected = this.state.selected;
    let count = 0;
    for(let k in selected) {
      if(selected[k]) {
        count += content[k].sentences.length;
      }
    }
    return (
      <div>
        <hr />
        <p>Edit the name of the article and select the sections you'd like to import.</p>
        <div className="form-group">
          <label>Name</label>
          <input name="name" className={'form-control' + (!this.state.name ? ' is-invalid' : '')} type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
        </div>
        {parts}
        <button className="btn btn-primary btn-lg" id="import" onClick={() => this.import()} disabled={!count} style={{ position: 'fixed', right: 15, bottom: 15 }}>Import {count} sentences</button>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderForm()}
        {this.renderLoading()}
        {this.renderResults()}
      </div>
    );
  }
}
 
export default WikipediaImporter;
