import React from "react";

class SlideoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  componentDidMount() {
  }

  show() {
    this.setState({ visible: true }, () => {
      $(this.modal)
        .one('hidden.bs.modal', () => this.setState({ visible: false }))
        .modal('show');
    });
  }

  hide() {
    $(this.modal).modal('hide');
  }

  render() {
    if(!this.state.visible) {
      return null;
    }

    return (
      <div className="modal" ref={(el) => this.modal = el}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideoutModal;

