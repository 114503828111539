import React from "react"
import PropTypes from "prop-types"
import Textarea from 'react-textarea-autosize';
import populateVoices from '../populateVoices';
import store from '../store';
import defaultGameSettings from '../defaultGameSettings';
import Modal from './Modal';
import PhraseEditor from './PhraseEditor';
import getSelectionText from '../getSelectionText';
const rtlDetect = require('rtl-detect');

const LANGUAGE_ACCENT_MAP = {
  es: {'A':'á', 'E':'é', 'I':'í', 'O':'ó', 'U':'úü',                    // Spanish
      'N':'ñ', '!':'¡', '?':'¿'},
  fr: {'A':'àâæ', 'E':'èéêë', 'I':'îï', 'O':'ôœ', 'U':'ùûü', 'C':'ç'},  // French
  pt: {'A':'ãáâà', 'E':'éê', 'I':'í', 'O':'õóô', 'U':'úü', 'C':'ç'},    // Portuguese
  de: {'A':'ä', 'O':'ö', 'U':'ü', 'S':'ß'},                             // German
  it: {'A':'àá', 'E':'èé', 'I':'ìí', 'O':'òó', 'U':'ùú'},               // Italian
  pl: {'A':'ą', 'C':'ć', 'E':'ę', 'L':'ł', 'N':'ń',                     // Polish
      'O':'ó', 'S':'ś', 'X':'ź', 'Z':'żź'},
  ro: {'A':'ăâ', 'I':'î', 'S':'şș', 'T':'ţț'},                          // Romanian
  hu: {'A':'á', 'E':'é', 'I':'í', 'O':'öóő', 'U':'üúű'},                // Hungarian
  nl: {'A':'á', 'E':'éèë', 'I':'ï', 'O':'óö', 'U':'ü'},                 // Dutch (Netherlands)
  tr: {'C':'ç', 'G':'ğ', 'I':'ıİ', 'O':'ö', 'S':'ş', 'U':'ü'},          // Turkish
  da: {'A':'åæ', 'O':'ø'},                                              // Danish
  ga: {'A':'á', 'E':'é', 'I':'í', 'O':'ó', 'U':'ú'},                    // Irish
  sv: {'A':'åä', 'O':'ö', 'E':'é'},                                     // Swedish
  eo: {'C':'ĉ', 'G':'ĝ', 'H':'ĥ', 'J':'ĵ', 'S':'ŝ', 'U':'ŭ'},           // Esperanto
  nb: {'A':'åæ', 'O':'ø', 'E':'é'}, // Norwegian
  et: {'A':'ä', 'O':'öõ', 'U':'ü'}, // Estonian
  sk: {'A':'áä', 'C':'č', 'D':'ď', 'E':'é', 'I':'í', 'L':'ľĺ', 'N':'ň', 'O':'óô', 'R':'ŕ', 'S':'š', 'T':'ť', 'U':'ú', 'Y':'ý', 'Z':'ž'}, // Slovak
  is: {'A':'áæ', 'D':'ðþ', 'E':'é', 'I':'í', 'O':'óö', 'U':'ú', 'Y':'ý'}, // Icelandic
};

class TranslationEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translation: props.translation
    };
  }

  save() {
    this.props.onSave(this.state.translation);
  }

  render() {
    return (
      <>
        <Textarea className="form-control mb-2" autoFocus={true} disabled={this.props.updating} value={this.state.translation || ''} onChange={(e) => this.setState({ translation: e.target.value })}></Textarea>
        <div className="text-right">
          <button disabled={this.props.updating} onClick={() => this.save()} className="btn btn-sm btn-primary">Save</button>
          <button disabled={this.props.updating} onClick={() => this.props.onCancel()} className="btn btn-sm btn-link ml-2">Cancel</button>
        </div>
      </>
    );
  }
}

class NoteEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: props.note
    };
  }

  save() {
    this.props.onSave(this.state.note);
  }

  render() {
    const { demo, updating } = this.props;

    const textarea = (
      <Textarea
        className="note-textarea form-control my-2"
        autoFocus={true}
        readOnly={!!demo}
        disabled={updating}
        value={this.state.note || ''}
        onChange={(e) => this.setState({ note: e.target.value })}
      ></Textarea>
    );

    if(demo) {
      let message = '';
      if(demo === 'basic') {
        message = 'Sign up or sign in to save and edit notes!';
      }
      else if(demo === 'shared') {
        message = 'Import this challenge to save and edit notes!';
      }

      return (
        <>
          {textarea}
          <div>
            <small>{message}</small>
            <button onClick={() => this.props.onCancel()} className="btn btn-sm btn-link ml-2">Close</button>
          </div>
        </>
      );
    }

    return (
      <>
        {textarea}
        <div>
          <button disabled={this.props.updating} onClick={() => this.save()} className="btn btn-sm btn-primary">Save</button>
          <button disabled={this.props.updating} onClick={() => this.props.onCancel()} className="btn btn-sm btn-link ml-2">Cancel</button>
          <small className="ml-2">
            Enter any notes you'd like to take here. You can view all your notes via your dashboard.
          </small>
        </div>
      </>
    );
  }
}

class Completions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false
    };
  }

  render() {
    let { completions, getTimeStr } = this.props;

    if(!completions.length) {
      return null;
    }

    const { showAll } = this.state;

    completions = completions.slice(0).reverse();

    if(!showAll) {
      completions = [completions[0]];
    }

    return (
      <div>
        {completions.map((c, i) => (
          <div key={c.date}>
            <small>{c.date} Typos: {c.typos} Hints: {c.hints} Pauses: {c.pauses} Time: {getTimeStr(c.time)}</small>
            {i === 0 && this.props.completions.length > 1 && <button className="btn btn-link btn-sm" onClick={() => this.setState({ showAll: !showAll })}>{showAll ? 'Less' : 'More'}</button>}
          </div>
        ))}
      </div>
    );
  }
}


export default class Sentence extends React.Component {
  static defaultProps = {
    gameSettings: defaultGameSettings
  }

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.wordCountRef = React.createRef();
    this.id = 'sentence-' + (props.sentenceId ? props.sentenceId : new Date().getTime());
    this.isRtlLang = this.props.twoLetterLanguageCode && rtlDetect.isRtlLang(this.props.twoLetterLanguageCode);

    this.state = {
      complete: !!props.complete,
      completions: props.completions || [],
      correctSoFar: null,
      editingTranslation: false,
      elapsedTime: 0,
      favorited: props.favorited,
      highlightedPhrase: null,
      hintCount: 0,
      input: props.complete ? props.text : '',
      note: props.note,
      noteVisible: false,
      pauseCount: 0,
      phrases: props.phrases || [],
      playing: props.playing || false,
      showOverLimitModal: false,
      translation: props.translation,
      translationAccepted: props.translationAccepted || false,
      typoCount: 0,
      updating: false,
      voices: store.getState().voices,
    };
  }

  onInputChange(event) {
    const value = event.target.value;
    this.setInput(value);
  }

  setInput(value) {
    return new Promise((resolve, reject) => {
      const basicValue = value.replace(/\s+/, ' ');
      const basicText = this.props.text.replace(/\s+/, ' ');
      const complete = basicValue === basicText;
      // using #!# so we match from the start of the text
      const correctSoFar = (('#!#' + basicText).indexOf('#!#' + basicValue) >= 0);
      // if(!correctSoFar && !this.state.compositioning && this.props.gameSettings.soundEffects) {
      //   new Audio('/audio/incorrect.mp3').play();
      // }
      this.setState({
        input: value,
        complete,
        correctSoFar: this.compositioning ? this.state.correctSoFar : correctSoFar,
        wordCountVisible: complete,
        playing: !complete
      }, () => {
        if(complete) {
          this.onComplete();
        }
        resolve(complete);
      });
    });
  }

  onComplete() {
    if(this.props.sentenceId && !this.props.demo) {
      const { elapsedTime, hintCount, pauseCount, typoCount } = this.state;
      $.ajax({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        url: '/progress',
        method: 'post',
        data: {
          hint_count: hintCount,
          elapsed_time: elapsedTime,
          pause_count: pauseCount,
          sentence_id: this.props.sentenceId,
          typo_count: typoCount
        }
      }).done((data) => {
        this.props.onProgressUpdate && this.props.onProgressUpdate(data);
        this.setState({ completions: data.completions });
        if(data.overLimit) {
          this.setState({ showOverLimitModal: true });
        }
      });
    }

    setTimeout(() => {
      $(this.wordCountRef.current).fadeOut(() => this.setState({ wordCountVisible: false }));
    }, 1500);

    if(this.props.gameSettings.soundEffects && this.props.gameSettings.autoPlayTts) {
      new Audio('/audio/correct.mp3').play();
      setTimeout(() => this.playAudio(false), 500);
    }
    else if(this.props.gameSettings.soundEffects) {
      new Audio('/audio/correct.mp3').play();
    }
    else if(this.props.gameSettings.autoPlayTts) {
      this.playAudio(false);
    }

    this.props.onComplete && this.props.onComplete(this.props.sentenceId);
  }

  componentDidMount() {
    const gameInputSelector = '.game-input.form-control';
    $('#' + this.id)
      .on('copy paste', gameInputSelector, function(e) {
        e.preventDefault();
      })
      // https://developer.mozilla.org/en-US/docs/Mozilla/IME_handling_guide
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/compositionstart_event
      .on('compositionstart', gameInputSelector, () => {
        this.compositioning = true;
      })
      .on('compositionend', gameInputSelector, () => {
        this.compositioning = false;
      });

    this.unsubscribeVoices = store.subscribe(() => this.setState({ voices: store.getState().voices }));
    populateVoices();

    if(this.props.playing) {
      this.inputRef.focus();
      this.startElapsedTimeTimer();
    }

    $('#' + this.id).on('click', '.popover .phrase', (e) => {
      e.preventDefault();
      $('[data-toggle="popover"]').popover('hide');
      this.setState({ highlightedPhrase: $(e.target).text() });
    });
  }

  componentWillUnmount() {
    this.unsubscribeVoices && this.unsubscribeVoices();
  }

  getCurrentTimeInSeconds() {
    return Math.round(new Date().getTime() / 1000);
  }

  getCurrentDateStr() {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.playing && prevState.correctSoFar !== this.state.correctSoFar && !this.state.correctSoFar && !this.compositioning) {
      this.setState({ typoCount: this.state.typoCount + 1 });
    }
    if(prevState.playing !== this.state.playing) {
      if(this.state.playing) {
        this.startElapsedTimeTimer();
      }
      else {
        this.stopElapsedTimeTimer();
      }
    }
    if(prevState.playing !== this.state.playing && !this.state.playing && !this.state.complete) {
      this.setState({ pauseCount: this.state.pauseCount + 1 });
    }
    if(!prevState.complete && this.state.complete) {
      const { hintCount, pauseCount, elapsedTime, typoCount } = this.state;
      this.setState({
        completions: this.state.completions.concat([{
          date: this.getCurrentDateStr(),
          hints: hintCount,
          pauses: pauseCount,
          time: elapsedTime,
          typos: typoCount
        }])
      });
    }
  }

  startElapsedTimeTimer() {
    this.elapsedTimeTimer = setInterval(() => this.setState({ elapsedTime: Math.min(this.state.elapsedTime + 1, 300) }), 1000);
  }

  stopElapsedTimeTimer() {
    clearInterval(this.elapsedTimeTimer);
  }

  playAudio(alertIfNoVoice) {
    // android uses es_ES, we're using es-ES internally (same as mac os chrome)
    const regexp = new RegExp(this.props.voiceLocale.replace(/[-_]/, '.*'), 'i');
    const voices = this.state.voices || [];
    let voice = null;
    if(this.props.gameSettings.ttsVoice) {
      voice = voices.find((v) => v.name === this.props.gameSettings.ttsVoice);
    }
    // if no ttsVoice or still no voice after trying to find ttsVoice, attempt to find any matching
    if(!voice) {
      voice = (this.state.voices || []).find((v) => v.lang.match(regexp));
    }
    if(!voice && alertIfNoVoice !== false) {
      return alert('No voice found! Please wait a moment and try again, or try another browser. Google Chrome usually works best.');
    }
    if(!voice) {
      return false;
    }
    const u = new SpeechSynthesisUtterance(this.props.text);
    u.voice = voice;
    // seems like we have to set a lang for android, tbd if we select different voices
    u.lang = voice.lang;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(u);
  }

  renderPlayPauseResetBtn() {
    if(this.state.complete) {
      return <button className="btn btn-sm btn-light" onClick={this.onResetClick.bind(this)}>Reset</button>;
    }
    return <button className={'btn btn-sm btn-' + (this.state.playing ? 'light' : 'primary')} onClick={this.onPlayPauseClick.bind(this)}>{this.state.playing ? 'Pause' : 'Play'}</button>;
  }

  giveHint() {
    if(this.state.input.length && !this.state.correctSoFar) {
      return false;
    }

    const hint = this.props.text.slice(this.state.input.length, this.state.input.length + 1);

    this.setState({
      hintCount: this.state.hintCount + 1
    });

    this.setInput(this.state.input + hint).then((complete) => {
      if(!complete) {
        this.inputRef.focus();
        this.inputRef.selectionStart = this.inputRef.selectionEnd = this.state.input.length;
      }
    });
  }

  renderHintBtn() {
    if(!this.state.playing || (this.state.input.length && !this.state.correctSoFar)) {
      return null;
    }
    return <button className="btn btn-sm btn-light ml-2" onClick={() => this.giveHint()}>Hint</button>;
  }

  onResetClick() {
    this.setState({
      complete: false,
      correctSoFar: null,
      elapsedTime: 0,
      hintCount: 0,
      input: '',
      pauseCount: 0,
      playing: false,
      typoCount: 0,
      wordCountVisible: false
    }, () => this.props.onReset && this.props.onReset(this.props.sentenceId));
  }

  play(callback) {
    this.setState({ translationAccepted: true, playing: true }, () => callback && callback());
  }

  onPlayPauseClick() {
    this.setState({ playing: !this.state.playing }, () => {
      if(this.state.playing) {
        this.inputRef.focus();
      }
    });
  }

  getScrollTop() {
    return $(this.inputRef).parents('.sentence').offset().top;
  }

  onAccentButtonPress(event, accent) {
    const x = this.inputRef.selectionStart;
    if(event.shiftKey) {
      accent = accent.toUpperCase();
    }
    this.setInput(this.state.input.substring(0, x) + accent + this.state.input.substring(x)).then((complete) => {
      if(!complete) {
        this.inputRef.focus();
        this.inputRef.selectionStart = this.inputRef.selectionEnd = x + 1;
      }
    });
  }

  renderAccentButtons() {
    if(!this.state.playing) {
      return null;
    }

    if(!LANGUAGE_ACCENT_MAP[this.props.twoLetterLanguageCode]) {
      return null;
    }

    let accents = Object.values(LANGUAGE_ACCENT_MAP[this.props.twoLetterLanguageCode]).join('').split('');
    const additionalChars = this.props.text.match(new RegExp('[^a-z.\\d\\s:",;\'?!@#$%&*()\\-=_+\\[\\]\\|/{}' + accents.join('') + ']', 'ig'));
    if(additionalChars) {
      // only uniques, https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
      accents = accents.concat(additionalChars.filter((value, index, self) => self.indexOf(value) === index));
    }

    let buttons = [];
    for(let i = 0, n = accents.length; i < n; i++) {
      buttons.push(<button key={accents[i]} onClick={(e) => this.onAccentButtonPress(e, accents[i])} className="btn btn-secondary btn-sm">{this.state.shiftKey ? accents[i].toUpperCase() : accents[i]}</button>);
    }

    // hidden on mobile, assuming keyboard easy accents
    return (
      <div className="d-none d-sm-block" style={{ position: 'absolute', left: '50%', bottom: 20, maxWidth: '50%' }}>
        <div className="btn-group" style={{ marginLeft: '-50%' }}>
          {buttons}
        </div>
      </div>
    );
  }

  onInputKeyDown(event) {
    const key = event.which || event.keyCode;
    if(event.ctrlKey && key === 32) { // space bar
      this.playAudio(true);
      event.preventDefault();
      return false;
    }
    if(event.ctrlKey && key === 72) { // h
      this.giveHint();
      event.preventDefault();
      return false;
    }

    this.setState({ shiftKey: event.shiftKey });
  }

  onInputKeyUp(event) {
    this.setState({ shiftKey: event.shiftKey });
  }

  deleteSentence() {
    if(confirm('Are you sure you want to delete this sentence? This action cannot be undone.')) {
      this.props.onDelete && this.props.onDelete();

      $.ajax({
        method: 'delete',
        url: '/sentences/' + this.props.sentenceId
      }).fail(() => {
        alert('Oh no! There was an error deleting that sentence. Sorry about that. Please refresh the page and try again, and let us know if you see this error message again.');
      });
    }
  }

  update(attrs) {
    if(!this.props.sentenceId || this.props.demo) {
      if(this.props.demo === 'shared') {
        this.props.demoAlert();
      }
      this.setState(attrs);
      this.props.onUpdate && this.props.onUpdate(attrs);
      return false;
    }

    this.setState({ updating: true });
    $.ajax({
      method: 'put',
      url: '/sentences/' + this.props.sentenceId,
      data: { sentence: attrs }
    })
      .done(() => {
        this.setState(attrs);
        this.props.onUpdate && this.props.onUpdate(attrs);
      })
      .fail(() => {
        alert('Oh no! There was an error updating the sentence. Sorry about that. Please refresh the page and try again, and let us know if you see this error message again.');
      })
      .always(() => {
        this.setState({ updating: false })
      });
  }

  toggleFavorite() {
    this.update({ favorited: !this.state.favorited });
  }

  onNoteBtnClick() {
    this.setState({ noteVisible: !this.state.noteVisible });
  }

  renderNoteBtn() {
    if(this.props.noteable) {
      return (
        <button disabled={this.state.updating} className={'btn btn-sm ml-2 note-btn ' + (this.state.note ? 'btn-warning' : 'btn-light')} onClick={() => this.onNoteBtnClick()}><i className="fa fa-sticky-note"></i></button>
      );
    }
  }

  renderNote() {
    if(this.state.noteVisible) {
      return (
        <NoteEditor
          demo={this.props.demo}
          updating={this.state.updating}
          note={this.state.note}
          onSave={(note) => {
            this.setState({ noteVisible: false });
            this.update({ note: note });
          }}
          onCancel={() => this.setState({ noteVisible: false })}
        />
      );
    }
  }

  renderDeleteBtn() {
    if(this.props.deleteable) {
      return (
        <button disabled={this.state.updating} className="btn btn-sm btn-light ml-2" onClick={() => this.deleteSentence()}><i className="fa fa-trash"></i></button>
      );
    }
  }

  renderFavoriteBtn() {
    if(this.props.favoriteable) {
      return (
        <button disabled={this.state.updating} className="btn favorite-btn btn-sm btn-light ml-2" onClick={() => this.toggleFavorite()}>
          <i className={'fa fa-heart' + (this.state.favorited ? '' : '-o')}></i>
        </button>
      );
    }
  }

  renderAudioBtn() {
    return (
      <button className="btn btn-sm btn-light ml-2" onClick={() => this.playAudio()}><i className="fa fa-volume-up"></i></button>
    );
  }

  renderTranslation() {
    if(this.state.editingTranslation) {
      return (
        <div className="mb-2">
          <TranslationEditor
            updating={this.state.updating}
            translation={this.state.translation}
            onSave={(translation) => {
              this.setState({ editingTranslation: false });
              this.update({ translation: translation });
            }}
            onCancel={() => this.setState({ editingTranslation: false })}
          />
        </div>
      );
    }
    const editBtn = <a href="javascript:void(0);" onClick={() => this.setState({ editingTranslation: true })} className="edit-translation"><i className="fa fa-pencil"></i></a>;
    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>{this.state.translation} {this.props.editableTranslation && editBtn}</div>
    );
  }

  renderSource() {
    if(!this.props.articleName && !this.props.source) {
      return null;
    }

    return (
      <div className="text-right">
        <small>
          {this.props.articleName && <span className={this.props.gameSettings.darkMode ? 'text-light' : 'text-muted'}>{this.props.articleName}</span>}
          {this.props.source && <a className="ml-1" href={this.props.source} target="_blank">Source</a>}
        </small>
      </div>
    );
  }

  onPhraseSave(phrase) {
    let phrases = this.state.phrases.slice(0);
    let updated = false;
    for(let i = 0, n = phrases.length; i < n; i++) {
      if(phrases[i].id === phrase.id) {
        phrases[i] = phrase;
        updated = true;
        break;
      }
    }
    if(!updated) {
      phrases.push(phrase);
    }
    this.setState({ phrases: phrases });
    this.phraseEditorModal.hide();
  }

  onPhraseDelete(phraseId) {
    this.setState({ phrases: this.state.phrases.filter((p) => p.id !== phraseId) });
    this.phraseEditorModal.hide();
  }

  renderPhraseSlideout() {
    if(this.props.phrasesPath && this.state.highlightedPhrase) {
      return (
        <Modal title="Phrase Deck" ref={(el) => this.phraseEditorModal = el} onHidden={() => this.setState({ highlightedPhrase: null })}>
          <PhraseEditor
            demo={this.props.demo}
            sentenceId={this.props.sentenceId}
            phrasesPath={this.props.phrasesPath}
            links={this.props.phraseEditorLinks}
            text={this.state.highlightedPhrase}
            onSave={(phrase) => this.onPhraseSave(phrase)}
            onDelete={(phraseId) => this.onPhraseDelete(phraseId)}
            includeHint={true}
          />
        </Modal>
      );
    }
  }

  renderPhraseBtn() {
    if(this.state.phrases.length) {
      // show list of phrases associated with sentence, edit / delete
      return (
        <button disabled={this.state.updating} className="btn phrases-btn btn-sm btn-warning ml-2" data-html="true" data-title="Phrases" data-content={"<ul className=\"mb-0\">" + this.state.phrases.map((p) => "<li><a href=\"#\" class=\"phrase\">" + p.text + "</a></li>").join('') + "</ul>"} data-toggle="popover" data-placement="bottom" data-container={'#' + this.id}>
          <i className="fa fa-book"></i>
        </button>
      );
    }
  }

  renderShareBtn() {
    // social
    // embed
    // forum
    //
    // return (
    //   <button disabled={this.state.updating} className="btn phrases-btn btn-sm btn-light ml-2" onClick={() => {}}>
    //     <i className="fa fa-share-alt"></i>
    //   </button>
    // );
  }

  onMouseUp() {
    if(this.state.playing) {
      return false;
    }

    const selection = getSelectionText();
    if(this.props.text.match(selection)) {
      this.setState({ highlightedPhrase: selection });
    }
  }

  renderGameControls() {
    return (
      <>
        <Textarea
          disabled={!this.state.playing || this.state.complete}
          className={[
            'game-input',
            'form-control',
            (!this.state.complete && this.props.gameSettings.darkMode && 'bg-dark'),
            (!this.state.complete && this.props.gameSettings.darkMode && !this.state.input && 'text-white'),
            (!this.state.complete && this.state.correctSoFar && 'text-success'),
            (!this.state.complete && this.state.correctSoFar === false && 'text-danger'),
            (this.state.complete && 'text-white bg-success'),
          ].filter((c) => c).join(' ')}
          value={this.state.input}
          onChange={this.onInputChange.bind(this)}
          ref={(el) => this.inputRef = el && el._ref}
          onKeyDown={this.onInputKeyDown.bind(this)}
          onKeyUp={this.onInputKeyUp.bind(this)}
          style={this.isRtlLang ? { direction: 'rtl' } : null}
        ></Textarea>
        <div className="row" style={{ marginTop: 4 }}>
          <div className="col-9" style={{ marginTop: 4 }}>
            {this.renderPlayPauseResetBtn()}
            {this.renderHintBtn()}
            {this.renderAudioBtn()}
            {this.renderFavoriteBtn()}
            {this.renderNoteBtn()}
            {this.renderPhraseBtn()}
            {this.renderShareBtn()}
          </div>
          <div className="col-3 text-right" style={{ marginTop: 4 }}>
            {this.renderDeleteBtn()}
          </div>
        </div>
        {this.renderNote()}
        {this.renderAccentButtons()}
        <div className="text-success logo" ref={this.wordCountRef} style={{ display: this.state.wordCountVisible ? 'block' : 'none', zIndex: 999, textShadow: '6px 6px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000', position: 'fixed', top: '20%', left: 0, textAlign: 'center', width: '100%', fontWeight: 'bold', fontSize: '10em' }}>
          +{this.props.wordCount}
        </div>
      </>
    );
  }

  onTranslationAccepted() {
    if(this.props.sentenceId && !this.props.demo) {
      // not using update - don't want to wait for respone or update state from attributes
      $.ajax({
        method: 'put',
        url: '/sentences/' + this.props.sentenceId,
        data: { sentence: { translation_accepted: true } }
      });
    }
    this.setState({ translationAccepted: true }, () => this.onPlayPauseClick());
  }

  renderPreTranslationAcceptedControls() {
    return (
      <div className="mt-2">
        <div className="row">
          <div className="col-9">
            <button className="btn btn-primary btn-sm" onClick={() => this.onTranslationAccepted()}>Accept Translation</button>
            <button className="ml-2 btn btn-outline-primary btn-sm" onClick={() => this.setState({ editingTranslation: true })}>Edit Translation</button>
            {this.renderAudioBtn()}
            {this.renderFavoriteBtn()}
            {this.renderNoteBtn()}
            {this.renderPhraseBtn()}
            {this.renderShareBtn()}
          </div>
          <div className="col-3 text-right" style={{ marginTop: 4 }}>
            {this.renderDeleteBtn()}
          </div>
        </div>
        {this.renderNote()}
      </div>
    );
  }

  renderOverLimitModal() {
    if(this.state.showOverLimitModal) {
      return (
        <Modal noClose={true} size="large" title="Daily Word Limit">
          <div className="text-center">
            <h4 className="mb-4">Oh no! You've hit your daily limit for the free plan!</h4>
            <h4 className="mb-4">Subscribe for unlimited access to <span className="logo">The Great Translation Game</span>.</h4>
          </div>
          <a href="/subscribe" className="btn btn-block btn-primary btn-lg">Subscribe!</a>
          <div className="mt-1 text-center"><small>or go back to your <a href="/">Dashboard</a></small></div>
        </Modal>
      );
    }
  }

  getTimeStr(t) {
    const seconds = t % 60;
    return Math.floor(t / 60) + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  renderCompletions() {
    return (
      <Completions
        completions={this.state.completions}
        getTimeStr={(t) => this.getTimeStr(t)}
      />
    );
  }

  renderStats() {
    const { complete, elapsedTime, hintCount, pauseCount, translationAccepted, typoCount } = this.state;

    if(!translationAccepted) {
      return null;
    }

    if(complete) {
      return this.renderCompletions();
    }

    return (
      <div><small>Typos: {typoCount} Hints: {hintCount} Pauses: {pauseCount} Time: {this.getTimeStr(elapsedTime)}</small></div>
    );
  }

  render() {
    return (
      <div id={this.id} className={'sentence mb-3' + (this.state.complete ? ' complete' : '')} ref={(el) => this.ref = el}>
        <div className={'card' + (this.props.gameSettings.darkMode ? ' bg-dark text-white' : '')}>
          <div className="card-body">
            <div className={'text ' + (this.state.playing ? ((this.props.gameSettings.darkMode ? 'text-dark' : 'text-white') + ' disable-selection') : '')} style={{ fontSize: '1.25em', direction: this.isRtlLang ? 'rtl' : null }} onMouseUp={() => this.onMouseUp()}><strong>{this.props.text}</strong></div>
            {this.renderTranslation()}
            {this.state.translationAccepted ? this.renderGameControls() : this.renderPreTranslationAcceptedControls()}
            {this.renderStats()}
          </div>
        </div>
        {this.renderSource()}
        {this.renderPhraseSlideout()}
        {this.renderOverLimitModal()}
      </div>
    );
  }
}
