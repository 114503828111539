import React from "react"

class WordsPerSentenceFromGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      min: parseInt(props.min) || 1,
      max: parseInt(props.max) || 50
    };
  }

  renderMinOptions() {
    let options = [];
    for(let i = 1, n = 20; i <= n; i++) {
      options.push(<option key={i} value={i} disabled={i > this.state.max}>{i}</option>);
    }
    return options;
  }

  renderMaxOptions() {
    let options = [];
    for(let i = 4, n = 50; i <= n; i++) {
      options.push(<option key={i} value={i} disabled={i < this.state.min}>{i}</option>);
    }
    return options;
  }

  onMinChange(e) {
    const newMin = parseInt(e.target.value);
    this.setState({
      min: newMin,
      max: newMin > this.state.max ? newMin : this.state.max
    });
  }

  onMaxChange(e) {
    const newMax = parseInt(e.target.value);
    this.setState({
      min: newMax < this.state.min ? newMax : this.state.min,
      max: newMax
    });
  }

  render() {
    return (
      <div className="form-group">
        <label>Keep sentences with more than <select name="words_per_sentence_min" onChange={this.onMinChange.bind(this)} value={this.state.min}>{this.renderMinOptions()}</select> words</label> <label>and less than <select name="words_per_sentence_max" onChange={this.onMaxChange.bind(this)} value={this.state.max}>{this.renderMaxOptions()}</select> words.</label>
      </div>
    );
  }
}

export default WordsPerSentenceFromGroup;
