import React from "react";

class Modal extends React.Component {
  componentDidMount() {
    this.$modal = $(this.modal);
    this.$modal.on('hidden.bs.modal', () => this.props.onHidden && this.props.onHidden());
    this.$modal.on('shown.bs.modal', () => this.props.onShown && this.props.onShown());
    this.$modal.modal('show');
  }

  hide() {
    this.$modal.modal('hide');
  }

  renderFooter() {
    if(!this.props.footer) {
      return null;
    }
    // <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
    // <button type="button" className="btn btn-primary">Save changes</button>
    return (
      <div className="modal-footer">
        {this.props.footer}
      </div>
    );
  }

  render() {
    return (
      <div className="modal" data-keyboard={this.props.noClose ? "false" : null} data-backdrop={this.props.noClose ? "static" : null} tabIndex="-1" role="dialog" ref={(el) => this.modal = el}>
        <div className={'modal-dialog' + (this.props.size === 'large' ? ' modal-lg' : '')} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              {!this.props.noClose && (
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
