import { combineReducers, createStore } from 'redux';

const store = createStore(combineReducers({
  voices: (state = null, action) => {
    switch(action.type) {
      case 'UPDATE_VOICES':
        return action.voices;
      default:
        return state;
    }
  }
}));

export default store;
