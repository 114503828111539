import React from "react";
import SlideoutModal from './SlideoutModal';

class PhraseSlideout extends React.Component {
  show() {
    this.slideout.show();
  }

  hide() {
    this.slideout.hide();
  }

  render() {
    return (
      <SlideoutModal ref={(el) => this.slideout = el}>
        show sentence (sm)
        show phrase
        show related phrases
        show google translation in editable field
        show note in editable field
        show useful links
        show copy button
        show share button
        show post to forum button
      </SlideoutModal>
    );
  }
}

export default PhraseSlideout;
