import React from "react"
import PropTypes from "prop-types"

class GameStatus extends React.Component {
  componentDidMount() {
    $(this.menuColumn).append($('#header .dropdown'));
  }

  renderProgressBar() {
    if(!this.props.totalSentencesCount) {
      return null;
    }

    return (
      <div className="progress" style={{ height: 2 }}>
        <div className="progress-bar bg-success" role="progressbar" style={{ width: (this.props.sentencesCompletedCount / this.props.totalSentencesCount * 100) + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    );
  }

  renderWordsToNext() {
    return (
      <div className="mb-2" style={{ lineHeight: 1 }}>
        <small><small>Words to next level: </small>{!this.props.wordsToNextLevel ? <span>&#x1F389;</span> : this.props.wordsToNextLevel}<small> / <span className="d-xs-none">next </span>rank: </small>{this.props.currentRank === 1 ? <span>&#x1F389;</span> : (this.props.wordsToNextRank == null ? '?' : this.props.wordsToNextRank)}</small>
      </div>
    );
  }

  render() {
    return (
      <div className="bg-dark text-white" style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}>
        <div className="container-fluid position-relative">
          <div className="logo d-inline pl-3">
            <a href="/" className="text-white text-decoration-none">The Great Translation Game</a>
          </div>
          <div ref={(el) => this.menuColumn = el} style={{ position: 'absolute', right: 15, top: 0 }}></div>
          <div id="game-status-stats" className="text-center">
            <ul className="list-inline mb-0">
              <li className="list-inline-item"><small className="text-uppercase"><small>Sent<span className="d-xs-none">ence</span>s:</small></small> <strong>{this.props.sentencesCompletedCount}{this.props.totalSentencesCount ? ('/' + this.props.totalSentencesCount) : ''}</strong></li>
              <li className="list-inline-item"><small className="text-uppercase"><small>Words<span className="d-xs-none"> today</span>:</small></small> <strong>{this.props.wordsPlayedToday}</strong></li>
              <li className="list-inline-item"><small className="text-uppercase"><small>Rank:</small></small> <strong>{this.props.currentRank || '?'}</strong></li>
            </ul>
            {this.renderWordsToNext()}
          </div>
        </div>
        {this.renderProgressBar()}
      </div>
    );
  }
}

export default GameStatus;
