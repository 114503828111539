import React from "react"
import PropTypes from "prop-types"
import Sentence from './Sentence';
import GameSettings from './GameSettings';
import defaultGameSettings from '../defaultGameSettings';
import GameStatus from './GameStatus';
import LeveledUpModal from './LeveledUpModal';

class Review extends React.Component {
  static defaultProps = {
    gameSettings: defaultGameSettings
  }

  constructor(props) {
    super(props);
    this.state = {
      sentences: props.sentences,
      sentencesCompletedCount: 0,
      wordsPlayedToday: props.wordsPlayedToday,
      currentRank: props.currentRank,
      wordsToNextRank: props.wordsToNextRank,
      wordsToNextLevel: props.wordsToNextLevel,
      gameSettings: props.gameSettings || defaultGameSettings,
      showControls: false,
      showLeveledUpModal: false
    };
  }

  componentDidMount() {
    if(!this.state.sentences || !this.state.sentences.length) {
      alert('Oh no! No sentences! Please go back to your dashboard and try again.');
    }

    $(window).on('keydown.review', (e) => {
      if(e.keyCode === 83 && e.altKey && !!this.skipButton) {
        e.preventDefault();
        this.nextSentence();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.sentences.length < 1) {
      this.getMoreSentences();
    }
  }

  componentWillUnmount() {
    $(window).off('keydown.review');
  }

  getMoreSentences() {
    $.ajax({ 
      method: 'get',
      url: this.props.moreSentencesPath
    })
      .done((data) => {
        if(!data.sentences || !data.sentences.length) {
          return alert('Oh no! No sentences! Please go back to your dashboard and try again.');
        }
        this.setState({ sentences: this.state.sentences.concat(data.sentences) });
      })
      .fail(() => {
        alert('Oh no! There was an error loading more sentences. Sorry about that. Please refresh the page and try again, and let us know if you see this message again.');
      });
  }

  onSentenceReset() {
  }

  onProgressUpdate(data) {
    this.setState({
      wordsPlayedToday: data.wordsPlayedToday,
      currentRank: data.currentRank,
      wordsToNextLevel: data.wordsToNextLevel,
      wordsToNextRank: data.wordsToNextRank,
      showLeveledUpModal: data.leveledUp
    });
  }

  onSentenceComplete() {
    this.setState({
      showControls: true,
      sentencesCompletedCount: this.state.sentencesCompletedCount + 1
    });
  }

  nextSentence() {
    const sentences = this.state.sentences;
    sentences.shift();
    this.setState({
      sentences: sentences,
      showControls: false
    });
  }

  renderSentence() {
    if(!this.state.sentences || !this.state.sentences.length) {
      return (
        <div className="text-center">
          <span className={'spinner-border' + (this.state.gameSettings.darkMode ? ' text-white' : '')} style={{ width: '4em', height: '4em' }}></span>
        </div>
      );
    }

    const sentence = this.state.sentences[0];
    return (
      <Sentence
        key={sentence.id}
        sentenceId={sentence.id}
        text={sentence.text}
        translation={sentence.translation}
        translationAccepted={true}
        wordCount={sentence.wordCount}
        complete={false}
        playing={true}
        twoLetterLanguageCode={sentence.twoLetterLanguageCode}
        voiceLocale={sentence.voiceLocale}
        onReset={this.onSentenceReset.bind(this)}
        onComplete={this.onSentenceComplete.bind(this)}
        onProgressUpdate={this.onProgressUpdate.bind(this)}
        gameSettings={this.state.gameSettings}
        articleName={sentence.articleName}
        source={sentence.source}
        phrasesPath={this.props.phrasesPath}
        phraseEditorLinks={this.props.phraseEditorLinks}
        phrases={sentence.phrases}
        favorited={sentence.favorited}
        favoriteable={this.props.sentencesFavoriteable}
        note={sentence.note}
        noteable={this.props.sentencesNoteable}
        deleteable={this.props.sentencesDeleteable}
        onDelete={() => this.nextSentence()}
        onUpdate={(attrs) => {
          let sentences = this.state.sentences;
          for(var i = 0, n = sentences.length; i < n; i++) {
            if(sentences[i].id === sentence.id) {
              // attrs hash, loop through keys setting sentence values
              for(var k in attrs) {
                sentences[i][k] = attrs[k];
              }
              break;
            }
          }
          this.setState({ sentences: sentences });
        }}
        editableTranslation={this.props.editableTranslations}
      />
    );
  }

  renderSettings() {
    return (
      <GameSettings
        gameSettings={this.state.gameSettings}
        twoLetterLanguageCode={this.props.twoLetterLanguageCode}
        onUpdate={(gameSettings) => this.setState({ gameSettings })}
        updateGameSettingsPath={this.props.updateGameSettingsPath}
      />
    );
  }

  renderStatus() {
    return (
      <GameStatus
        sentencesCompletedCount={this.state.sentencesCompletedCount}
        wordsPlayedToday={this.state.wordsPlayedToday}
        currentRank={this.state.currentRank}
        wordsToNextLevel={this.state.wordsToNextLevel}
        wordsToNextRank={this.state.wordsToNextRank}
      />
    );
  }

  renderControls() {
    if(!this.state.showControls) {
      return null;
    }

    return (
      <div className="text-center">
        <button onClick={() => this.nextSentence()} className="btn btn-primary btn-lg" autoFocus={true}>Next Sentence</button>
      </div>
    );
  }

  renderLeveledUpModal() {
    if(this.state.showLeveledUpModal) {
      return (
        <LeveledUpModal
          level={this.state.showLeveledUpModal}
          darkMode={this.state.gameSettings.darkMode}
          languageEnglishName={this.props.languageEnglishName}
          onHidden={() => this.setState({ showLeveledUpModal: false })}
        />
      );
    }
  }

  renderSkipButton() {
    if(this.state.showControls) {
      return null;
    }

    return (
      <div className="text-center">
        <button ref={(el) => this.skipButton = el} onClick={() => this.nextSentence()} className="btn btn-outline-primary">Skip (alt+s)</button>
      </div>
    );
  }

  render() {
    return (
      <div className="mb-5">
        {this.renderStatus()}
        {this.renderSentence()}
        {this.renderSkipButton()}
        {this.renderControls()}
        {this.renderSettings()}
        {this.renderLeveledUpModal()}
      </div>
    );
  }
}
 
export default Review;
