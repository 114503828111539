import React from "react"
import populateVoices from '../populateVoices';
import store from '../store';

export default class VoiceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      voices: store.getState().voices
    };
  }

  componentDidMount() {
    store.subscribe(() => this.setState({ voices: store.getState().voices }));
    populateVoices();
  }

  render() {
    if(!this.state.voices) {
      return (
        <div>No voices available on this browser/device.</div>
      );
    }
    let voices = this.state.voices.slice(0);
    voices.sort((a, b) => {
      if(a.lang < b.lang) return -1;
      if(a.lang > b.lang) return 1;
      return 0;
    });
    if(!this.state.expanded) {
      voices = voices.slice(0, 5);
    }
    return (
      <div>
        <div><strong>Voices available on this browser/device:</strong></div>
        <table className="mb-0 table" style={{ width: 'inherit' }}>
          <tbody>
            {voices.map((v) => <tr key={v.name}><td>{v.lang}</td><td>{v.name}</td></tr>)}
          </tbody>
        </table>
        <a href="javascript:void(0);" onClick={() => this.setState({ expanded: !this.state.expanded })}>
          {this.state.expanded ? 'Less' : 'More'}
        </a>
      </div>
    );
  }
}
