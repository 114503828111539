import React from "react"
import ConfettiGenerator from "confetti-js";

export default class LeveledUpModal extends React.Component {
  componentDidMount() {
    const confettiSettings = {"target":"confetti-holder","max":"80","size":"1","animate":true,"props":["circle","square","triangle","line"],"colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],"clock":"25","rotate":false,"width":"","height":""}
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    $('#leveled-up-modal')
      .modal('show')
      .on('hidden.bs.modal', () => {
        this.props.onHidden();
      });

    window.addthis &&
      window.addthis.layers &&
      window.addthis.layers.refresh &&
      window.addthis.layers.refresh();

    $(this.tada).css({ transition: 'all 1s' });
    const loop = (s) => {
      $(this.tada).css('transform', 'scale(' + (s ? 1 : 1.2) + ')');
      this.loopTimeout = setTimeout(() => loop(!s), 1000);
    };
    loop(true);
  }

  componentWillUnmount() {
    clearTimeout(this.loopTimeout);
  }

  renderBody() {
    if(this.props.level === 50) {
      return (
        <div>
          <div className="display-4" ref={(el) => this.tada = el}>&#x1F3C6;</div>
          <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>&#x1F389; Level 50 &#x1F389;</div>
          <div className="display-4">&#x1F929;</div>
          <div>Congrats! You have conquered The Great Translation Game!</div>
        </div>
      );
    }
    return (
      <div>
        <div className="display-4" ref={(el) => this.tada = el}>&#x1F389;</div>
        <div style={{ fontSize: '1.5rem' }}><strong>Level {this.props.level}</strong></div>
        <div>&#x1F680; Keep it up! &#x1F680;</div>
        <div style={{ fontSize: '2rem' }}>&#x1f64c;</div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <canvas id="confetti-holder" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}></canvas>
        <div id="leveled-up-modal" className="modal fade">
          <div className="modal-dialog" style={{ marginTop: '4rem' }}>
            <div className="modal-content" style={{ background: this.props.darkMode ? '#000' : null }}>
              <div className="modal-body text-center">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="display-4">Leveled up!</div>
                {this.renderBody()}
                <div>Tell the world!</div>
                <div className="addthis_inline_share_toolbox" data-url="https://www.thegreattranslationgame.com" data-title={'I just leveled up my ' + this.props.languageEnglishName + ' playing The Great Translation Game! Level ' + this.props.level + ' - wahoo!'}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
